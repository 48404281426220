import { useState} from "react";
import {
    UserCircleIcon,
    LockClosedIcon,
    CreditCardIcon,
    BellIcon,
    UserIcon,
    ChevronDoubleLeftIcon
} from '@heroicons/react/outline'
import EditCompany from "../../views/Company/EditCompany";
import Users from "../../views/User/Users";

const Account = () => {

  const [activeSection, setActiveSection] = useState('account');

  const [showContent, setShowContent] = useState(false);

  const handleButtonClick = () => {
    setShowContent(!showContent);
  };

   const handleClick = (link) => {
       setActiveSection(activeSection === link ? null : link);
       handleButtonClick();
     };

  const settings = [
    { id: 1, icon: UserCircleIcon, title: 'Account', sub: 'Manage your public profile and private information', button: 'Go to FAQs', link: 'account', component: EditCompany},
    { id: 2, icon: LockClosedIcon, title: 'Security', sub: 'Manage your password and 2-step verification preferences', button: 'Check guides', link: 'security', component: EditCompany},
    { id: 3, icon: CreditCardIcon, title: 'Plan & Billing', sub: 'Manage your subscription plan, payment method and billing information', button: 'Contact us', link: 'billing', component: EditCompany},
    { id: 4, icon: BellIcon, title: 'Notifications', sub: 'Manage when you will be notified on which channels', button: 'Contact us', link: 'notifications', component: EditCompany},
    { id: 5, icon: UserIcon, title: 'Teams', sub: 'Manage your existing team and change roles/permissions', button: 'Contact us', link: 'teams', component: Users}
  ]


  return (
   <>

   <main className="min-w-0 flex-1 grid lg:flex dark:bg-gray-900 overflow-hidden">


   {settings.map((card) => (
      activeSection === card.link && (
      <section
        aria-labelledby="message-heading"
        className=
        {`card-wrap flex lg:min-w-0 max-w-4xl flex-1 flex-col overflow-hidden order-last
          ${activeSection ? 'active' : ''}`
        }

        key={card.link} // Add a unique key prop
        >

        <ul role="list" className="space-y-2 py-4 sm:space-y-4 px-0 lg:px-4">

        <div>
          <button className="ml-1 dark:text-blue-600 px-4" onClick={handleButtonClick}>
          {showContent ?
             <>
               <span><ChevronDoubleLeftIcon className="h-4 w-4 text-blue-600" aria-hidden="true" />Back to settings</span>
             </>
             : ''
           }          </button>
        </div>

          <li className="DELETE WHEN FINISHED">{card.title}</li>
           <card.component />
        </ul>
      </section>
      )
    ))}


<aside className="border-gray-200 sm:border-l-2 sm:border-r-2 dark:border-gray-700 order-first block lg:flex-shrink-0 dark:bg-gray-900 bg-white" style={{display: showContent ? 'none' : 'block'}}>
  <div
      className="relative flex w-100 lg:w-96 flex-col border-r border-l border-gray-200 dark:border-gray-600">
        <div className="p-10 text-4xl sm:text-4xl text-slate-800 dark:text-gray-100 font-extrabold leading-tight tracking-tight">Settings</div>
        <nav aria-label="Message list" className="min-h-0 flex-1">
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-500 setting-nav">

         {settings.map((card) => (

           <li key={card.id}
             className={`relative py-5 px-6 hover:bg-gray-50 dark:hover:bg-gray-700
               ${activeSection === card.link ? 'bg-gray-50 dark:bg-gray-700 dark:text-gray-200' : 'dark:text-gray-500'}`}
             onClick={() => handleClick(card.link)}>
              <div className="flex px-3 py-1 cursor-pointer bg-primary-50 dark:bg-hover">
             <div role="img" className="text-primary" aria-hidden="true">
              <card.icon className="w-10 h-10"/>
             </div>
             <div className="ml-3">
             <div className="font-bold leading-6 text-slate-700 dark:text-gray-300"> {card.title} </div>
             <div className="mt-0.5 text-xs text-slate-700 dark:text-gray-400"> {card.sub} </div>
             </div>
           </div>
           </li>

           ))}

          </ul>
        </nav>
      </div>
    </aside>
  </main>




   </>
    )
}
export default Account;
