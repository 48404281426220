import {createAsyncThunk} from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";


export const getTemplates = createAsyncThunk(
    'template/getTemplates',
    async (payload) => {

        return await fetchAPI(`/api/template/list`, payload)
    }
)

export const updateTemplate = createAsyncThunk(
    'template/updateTemplate',
    async (payload) => {
        return await fetchAPI(`/api/template/update`, payload)
    }
)

export const addTemplate = createAsyncThunk(
    'template/addTemplate',
    async (payload) => {
        return await fetchAPI(`/api/template/add`, payload)
    }
)
export const getTemplateById = createAsyncThunk(
    'template/getTemplateById',
    async (payload) => {
        return await fetchAPI(`/api/template/byId`, payload)
    }
)
