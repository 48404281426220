import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setLoading} from "../../store/features/app";
import {getTemplates} from "../../store/features/template/thunks";
import CreateButton from "../../components/UI/CreateButton";
import CreateTemplate from "../../components/UI/Modals/CreateTemplate";
import {useAuth} from "../../hooks/useAuth";
import {DocumentDuplicateIcon, PencilAltIcon, TrashIcon} from "@heroicons/react/solid";
import {find} from "lodash";
import templateImage from '../../assets/images/template.png'
import {setDeactivate} from "../../store/features/app";

const Templates = () => {
    const dispatch = useDispatch()

    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
    const [duplicateTemplate, setDuplicateTemplate] = useState(null)
    const company = useSelector(state => state.user?.company)
    const auth = useAuth()
    const companyId = company?.id
    const templates = useSelector(state => state.template?.list)

    useEffect(() => {

        if (companyId) {

            dispatch(setLoading(true))
            dispatch(getTemplates({companyId})).then(res => {
                dispatch(setLoading(false))
            })
        }

    }, [dispatch, companyId]);

    const showDeactivateModal = (template) => {

        let content, action;
        if (template.active) {
            action = "Deactivate"
            content = `Are you sure you want to deactivate template associated with title : ${template.name} ?`
        } else {
            action = "Activate"
            content = `Are you sure you want to activate template associated with title : ${template.name} ?`
        }
        const modalOptions = {
            show: true,
            entity: "template",
            action,
            id: template.id,
            content
        }
        dispatch(setDeactivate(modalOptions))
    }

    const handleTemplateDuplication = (templateId) => {

        const templateData = find(templates, (template) => template.id === templateId)
        setDuplicateTemplate(templateData)
        setShowCreateTemplateModal(true)
    }

    const showModal = (show) => {
        setShowCreateTemplateModal(show)
    }


    return (
        <>
            <>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-20">
                <div className="mb-4 mt-10"><CreateButton text={"Create Template"} onClick={() => setShowCreateTemplateModal(true)}/></div>
                {templates ? (
                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                        {templates && templates.map((template) => (<>
                            {template.active &&  <li
                                    key={template.id}
                                    className="col-span-1 flex flex-col text-center bg-white dark:bg-gray-800 rounded-lg shadow divide-y divide-gray-200"
                                >
                                    <div className="bg-white dark:bg-gray-800 px-4 py-5 border-b text-left border-gray-200 dark:border-gray-800 sm:px-6 rounded-t-md">
                                        <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{template.name}</h4>
                                    </div>
                                    <div className="aspect-w-3 aspect-h-3 overflow-hidden bg-gray-100">
                                        <img src={template.screenshot ? template.screenshot : templateImage} alt={template.name}
                                             className="object-center object-cover"/>
                                    </div>

                                    <div>
                                        <div className="-mt-px flex divide-x divide-gray-200 dark:divide-gray-600">
                                            <div className="w-0 flex-1 flex">

                                                <a
                                                    target='_blank'
                                                    href={`${process.env.REACT_APP_EBUILDER_URL}/editor?id=${template.id}&product=engagr&company=${company?.id}&userId=${auth.user?.id}`}
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <PencilAltIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                                    <span className="ml-1"></span>
                                                </a>
                                            </div>
                                            <div className="-ml-px w-0 flex-1 flex">
                                                <button
                                                    onClick={() => handleTemplateDuplication(template.id)}
                                                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                                >
                                                    <DocumentDuplicateIcon className="w-5 h-5 text-gray-400"
                                                                           aria-hidden="true"/>
                                                    <span className="ml-1"></span>
                                                </button>
                                            </div>
                                            <div className="-ml-px w-1 flex-1 flex">

                                                <button type="button" onClick={() => showDeactivateModal(template)}
                                                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                                >
                                                    <TrashIcon className="w-5 h-5 text-red-400" aria-hidden="true"/>
                                                    <span className="ml-1"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </li>}</>
                            )
                        )}
                    </ul>


                ) :(
                    <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <div className="m-3 ">
                            <h3 className="text-red-800">No templates found</h3>
                        </div>
                    </div>
                )}
                <CreateTemplate show={showCreateTemplateModal} onStateChange={showModal}
                                duplicateTemplateData={duplicateTemplate}/>

                                </div>
            </>
        </>
    )
}

export default Templates
