import {createSlice} from '@reduxjs/toolkit'
import dashboardInitialState from "./initialState";
import {getTopUsersWithDailyCount, getLeaderboard, getMOMUserEntries, getCurrentAndLastMonthTopEntries, getCurrentMonthEntriesByUser  } from './thunks'

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardInitialState,
    reducers: {},
    extraReducers: {
        [getLeaderboard.fulfilled]: (state, {payload}) => {
            state.leaderboards = payload.data

        },
        [getTopUsersWithDailyCount.fulfilled]: (state, {payload}) => {
            console.log(payload, 'top users payload')
            state.topUsersWithDailyCount = payload.data
        },

        [getMOMUserEntries.fulfilled]: (state, {payload}) => {
            const {current, previous} = payload
            state.momUserEntries = {current,previous}
        },
        [getCurrentAndLastMonthTopEntries.fulfilled]: (state, {payload}) => {
            if(payload.data){
                state.topEntries = payload.data
            }
        },
        [getCurrentMonthEntriesByUser.fulfilled]: (state, {payload}) => {
            if(payload.data){
                state.currentMonthEntriesByUser = payload.data
            }
        },

    },
})

export default dashboardSlice.reducer
