// import MainChart from "../views/Charts/mainChart";
// import SmallBarChart from "../views/Charts/smallBarChart";
// import SmallBarThinChart from "../views/Charts/smallBarThinChart";
// import DonutThinChart from "../views/Charts/DonutThinChart";
import DashboardHeader from "../views/Charts/headerChart";
import LeaderBoard from "../views/Charts/leaderBoard";
import LeaderBoardChart from "../views/Charts/leaderBoardChart";
import CurrentMonthEntriesByUser from "../views/Charts/CurrentMonthEntriesByUser.js";

const Dashboard = () =>{
    return(
      <>
      <div className="bg-gray-white dark:bg-gray-900 h-screen">
      <div className="p-5 bg-white dark:bg-gray-900">
      <div><DashboardHeader /></div>

      {/* <div className="w-full mt-4 grid gap-4 grid-cols-2">
        <div><SmallBarChart /></div>
        <div><SmallBarThinChart /></div>
      </div> */}
      <div className="mt-4"><LeaderBoard /></div>
      <div className="mt-4"><LeaderBoardChart /></div>
      <div className="mt-4"><CurrentMonthEntriesByUser /></div>
      </div>
    </div>
      </>
    )
}

export default Dashboard
