const formInitialValues = {
    photo: '',
    firstname: '',
    lastname: '',
    email: '',
    product:'',
    condition:'',
    contactnumber: '',
    addresslineone: '',
    addresslinetwo: '',
    city: '',
    state: '',
    postcode: '',
    leadsource: '',
    postoptions: '',
    hourstodelay: '',
    usedefaultpostsettings:'',
    postonfacebook: false,
    postoninstagram: false
}

export default formInitialValues;