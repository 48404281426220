import {createSlice} from '@reduxjs/toolkit'
import {addTemplate, getTemplates, getTemplateById, updateTemplate} from './thunks'

const templateInitialState = {
    id:"",
    name:"",
    subject:"",
    subTitle:"",
    screenShot:"",
    contentJson:"",
    contentMjml:"",
    contentHtml:"",
    mergeTags:"",
    product:"",
    companyId:"",
    createdOn:"",
    createdBy:"",
    updatedOn:"",
    updatedBy:"",
    active: true,
    list: null,
    isLoading: false

}
const templateSlice = createSlice({
    name: 'template',
    initialState: templateInitialState,
    reducers: {},
    extraReducers: {
        [getTemplates.fulfilled]: (state, {payload}) => {
            state.list = payload.data
            state.isLoading = false
        },
        [getTemplateById.fulfilled]: (state, {payload}) => {
            const data = payload.data[0]
            return {...state, ...data}
        },
        [addTemplate.fulfilled]: (state) => {
            state.isLoading = false
        },
        // [updateTemplate.fulfilled]:(state, payload) => {
        //     console.log(payload)
        // }
    },
})

export default templateSlice.reducer