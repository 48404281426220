import React from 'react'
import { ThemeContext } from '../../components/dark/themeContext'
//import logo from '../../assets/images/engagr-hoz.svg';

import lightMode from '../../assets/images/engagr-wh-hoz-02.svg';
import darkMode from '../../assets/images/engagr-hoz.svg';

export default function Brand({containerClass}){

    const { theme, setTheme } = React.useContext(ThemeContext)

    function isDark() {
      return theme === 'dark'
    }

    return (
        <div className={containerClass}>
            <img
                className="h-14 w-auto"
                src={isDark() ? lightMode : darkMode}
                alt="engagr"
            />
            <span className='text-white text-3xl px-3 mb-2 hidden'>engagr</span>
        </div>
    )
}
