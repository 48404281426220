import {useEffect, useState} from 'react'
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'
import {Combobox} from '@headlessui/react'
import {useField} from 'formik';
import {find} from "lodash";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ComboBox = (props) => {
    const {name, label, items, ...rest} = props;
    const [field, meta, helpers] = useField(props);
    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState(items[0])

    useEffect(()=>{
        if(field.value){
            const filterItem = find(items, (item)=> {
                return item.value === field.value
            })
           filterItem ? setSelected(filterItem) : setSelected(items[0])
        }
    },[items])

    const filteredItems =
        query === ''
            ? items
            : items.filter((item) => {
                return item.label.toLowerCase().includes(query.toLowerCase())
            })


    const handleChange = (item) => {
        setSelected(item)
        helpers.setValue(item.value)
    }
    return (
        <div className="mt-5">
            <Combobox as="div" name value={selected} onChange={handleChange}>
                <Combobox.Label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{label}</Combobox.Label>
                <div className="relative mt-1">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 dark:text-gray-100 py-2 pl-3 pr-10 shadow-sm sm:text-sm"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(item) => item?.label}
                    />
                    <Combobox.Button
                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon className="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true"/>
                    </Combobox.Button>

                    {filteredItems.length > 0 && (
                        <Combobox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredItems.map((item) => (
                                <Combobox.Option
                                    key={item.value}
                                    value={item}
                                    className={({active}) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-blue-800 text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({active, selected}) => (
                                        <>
                                            <span
                                                className={classNames('block truncate', selected && 'font-semibold')}>{item.label}</span>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    )}
                                                >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
        </div>
    )
}


export default ComboBox
