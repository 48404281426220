import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";

import {useAuth} from "../../hooks/useAuth";
import {updateCompany} from "../../store/features/user/thunks";
import {FileInputField, InputField} from "../../components/UI/FormControls";
import AddressForm from "../../components/Forms/AddressForm";
import {setAlert, setLoading} from "../../store/features/app";
import {uploadToSB} from "../../helpers/sbStorage";




const EditCompany = () => {
    const formInitialValues = {
        companyname: '',
        websiteurl: '',
        addresslineone: '',
        addresslinetwo: '',
        city: '',
        state: '',
        postcode: '',
    }
    const validationSchema = Yup.object().shape({
        companyname: Yup.string()
            .required('Company name is required'),
        addresslineone: Yup.string()
            .required('Address Line 1 is required'),
        city: Yup.string()
            .nullable()
            .required('City is required'),
        state: Yup.string()
            .nullable()
            .required('State is required'),
        postcode: Yup.string()
            .required('Postcode is required')
            .test(
                'len',
                `Postcode is not valid (e.g. 3000)`,
                val => val && val.length === 4
            ),
    })

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const company = useSelector(state => state.user?.company)

    const userId = useSelector(state => state.user?.id)
    const [fileToUpload, setFileToUpload] = useState(null)

    const fileUploadCB = (file) => {
        setFileToUpload(file)
    }

    const handleSubmit = async (values, actions) => {
        dispatch(setLoading(true))
        actions.setTouched({});
        actions.setSubmitting(true);
        const payload = {
            name: values.companyname,
            website: values.websiteurl,
            addresslineone: values.addresslineone,
            addresslinetwo: values.addresslinetwo,
            city: values.city,
            state: values.state,
            postcode: values.postcode,
            userId
        }

        if (fileToUpload) {
            const uploadedFile = await uploadToSB({companyName: values.companyname, fileToUpload, dispatch})

            if (uploadedFile.data) {
                payload.logo = uploadedFile.data
            }
        }

        dispatch(updateCompany({id:company.id, data: payload})).then(res => {

            if (res.payload.error) {
                dispatch(setAlert({
                    show: true,
                    type: "error",
                    content: res.payload.error
                }));
                dispatch(setLoading(false))
            } else {
                dispatch(setAlert({
                    show: true,
                    type: "success",
                    content: "Successfully updated company"
                }));
                dispatch(setLoading(false))
                //navigate(`/company`)
            }
        });

        actions.setSubmitting(false);
    }
    return (
        <>
            {company && <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({errors, touched, isSubmitting, setFieldValue}) => {

                    useEffect(() => {

                        if (company) {
                            setFieldValue('companyname', company.name ?? '')
                            setFieldValue('websiteurl', company.website ?? '')
                            setFieldValue('addresslineone', company.addresslineone ?? '')
                            setFieldValue('addresslinetwo', company.addresslinetwo ?? '')
                            setFieldValue('city', company.city ?? '')
                            setFieldValue('state', company.state ?? '')
                            setFieldValue('postcode', company.postcode ?? '')

                        }
                    }, [company])
                    return (


                        <Form id="edit-company">
                        <div>

                        <div className="space-y-6 py-6 px-4 sm:p-6">
                          <div>
                            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">Company Details</h3>
                            <p className="mt-1 text-sm text-gray-500">Please provide the following information about your company.</p>
                          </div>

                          <FileInputField fileUploadCB={fileUploadCB} fileUploaded={company.logo}/>

                          <div className="grid grid-cols-6 gap-6">

                            <div className="col-span-6 sm:col-span-3">
                              <InputField name='companyname' label='Name of the company' type="text" name="companyname" id="companyname" autoComplete="given-name" className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <InputField name='websiteurl' label='Website Url' id="websiteurl" className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>

                            <AddressForm/>

                          </div>
                        </div>

                      </div>

                        <div className="p-5">
                          
                                <div>
                                  <h3 className="text-base font-semibold leading-6 text-gray-900">Personal Information</h3>
                                  <p className="mb-5 text-sm text-gray-500">Communication details in case we want to connect with you. These will be kept private.</p>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                  <div className="col-span-6 sm:col-span-3">
                                    <InputField type="text" name="email" id="email" label='Email' autoComplete="given-name" className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <InputField type="text" name="phone" id="phone" label='Phone' autoComplete="family-name" className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>
                                  </div>
                                </div>

                                <div className="mt-7 border-gray-200 border-b-4 dark:border-gray-700"></div>

                            <div className="mx-auto max-w-7xl mt-10 mb-10 text-right">
                                <div className="col-start-1 col-end-3">
                                    <button type="submit"
                                            className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
                                             Save
                                    </button>
                                </div>
                            </div>

                          </div>
                        </Form>
                    )
                }}

            </Formik>}
        </>

    )
}

export default EditCompany
