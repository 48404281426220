import TableHead from "../../components/UI/Table/TableHead";
import {useDispatch, useSelector} from "react-redux";
import {getUsers} from "../../store/features/user/thunks";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {setDeactivate} from "../../store/features/app";
import {setLoading} from "../../store/features/app";
import CreateButton from "../../components/UI/CreateButton";
import {useAuth} from "../../hooks/useAuth";

import {
    UserCircleIcon,
    LockClosedIcon,
    CreditCardIcon,
    BellIcon,
    UserIcon,
    PencilIcon
} from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Users = () => {
    const navigate = useNavigate();
    const auth = useAuth()

    const [addUser, setAddUser] = useState(false)
    const users = useSelector(state => state.user?.list)
    const companyId = useSelector(state => state.user?.company?.id)
    const dispatch = useDispatch();


    useEffect(() => {
        if (addUser)
            navigate("/user/add", {replace: true});
    }, [addUser])

    useEffect(() => {

        dispatch(setLoading(true))
        dispatch(getUsers({companyId})).then((res) => {
            dispatch(setLoading(false))
        });


    }, [dispatch, companyId]);

    const showDeactivateModal = (user) => {
        let content, action;
        if (user.active) {
            action = "Deactivate"
            content = `Are you sure you want to deactivate user account associated with email address : ${user.email} ?`
        } else {
            action = "Activate"
            content = `Are you sure you want to activate user account associated with email address : ${user.email} ?`
        }
        const modalOptions = {
            show: true,
            entity: "user",
            action,
            id: user.id,
            content
        }
        dispatch(setDeactivate(modalOptions))
    }

    function getFirstLetter(str) {
      if (str.length > 0) {
        return str.charAt(0);
      }
      return '';
    }


    function generateRainbowColorFromText(text) {
       const rainbowColors = [
         "bg-amber-500", // orange
         "bg-rose-700", // red
         "bg-yellow-400", // yellow
         "bg-green-600", // green
         "bg-blue-600", // blue
         "bg-indigo-600", // indigo
         "bg-fuchsia-800", // #EE82EE violet / change to blue as its staff
       ];
       const textColors = [
         "text-white", // #FF0000
         "text-white", // #FFA500
         "text-black", // #FFFF00
         "text-black", // #008000
         "text-white", // #0000FF
         "text-white", // #4B0082
         "text-white", // #8A2BE2
       ];
       let hash = 0;
       for (let i = 0; i < text.length; i++) {
         hash = text.charCodeAt(i) + ((hash << 5) - hash);
       }
       const index = Math.abs(hash % rainbowColors.length);
       const color = rainbowColors[index];
       const textColor = textColors[index];
       const className = `${color}`;
       return { color, textColor, className };
     }


    return (
        <>
            <CreateButton text={"Create user"} onClick={() => setAddUser(true)}/>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 p-2 mt-1 mb-20">

          {users &&
           users.map((user, index) => {
             const { color, textColor, className } = generateRainbowColorFromText(user.role.name);

             return (
               <div key={index} className="relative mb-3 overflow-hidden p-3 sm:p-6 pb-0 rounded-lg bg-white border dark:bg-gray-700 dark:border-gray-700">
                 <div className="grid rid-rows-3 grid-flow-col">
                   <div className="flex items-center space-x-4">
                     <div className="flex-shrink-0">
                       <div className={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full ${className} ${textColor}`}>
                  <span className="font-medium uppercase">
                  {getFirstLetter(user.firstname)}{getFirstLetter(user.lastname)}
                  </span>
                </div>
               </div>
             <div className="flex-1 min-w-0">
              <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-blue-500"><Link to={`/user/${user.id}`}> {user.firstname} {user.lastname}</Link></h3>
              <p className="max-w-auto text-sm text-gray-400">Personal details and role.</p>
             </div>
          </div>

            <div className="grid content-center">
              {auth.userRole >= user?.role?.accesslevel && (
                  <>
                      <div>
                        <button type="button" onClick={() => showDeactivateModal(user)}
                              className={classNames(
                                  user.active
                                      ? 'text-red-600 border-red-600 hover:bg-red-700 focus:ring-red-500 text-white font-light'
                                      : 'text-green-600 border-green-600 hover:bg-green-700 focus:ring-green-500',
                                  'inline-flex items-center gap-x-2 float-right rounded-full border-2 shadow-sm px-4 py-2 text-sm hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 w-auto'
                              )}
                              ><UserCircleIcon className="-ml-0.5 w-5 h-5" aria-hidden="true"/>
                      {user.active ? 'Deactivate' : 'Activate'}</button></div>
                  </>
              )}
              </div>
            </div>

              <div className="mt-5 pt-4 border-t border-gray-200 dark:border-gray-500">
                <dl>
                  <div className="pb-3 grid grid-cols-3 gap-4 text-indigo-600 dark:text-gray-300">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Title</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 dark:text-gray-300 col-span-2 mt-0">
                      <span className="flex-grow">{user.title}</span>
                    </dd>
                  </div>
                  <div className="pb-3 grid grid-cols-3 gap-4 text-indigo-600 dark:text-gray-300">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Email address</dt>
                    <dd className="mt-1 flex text-sm col-span-2 mt-0">
                      <span className="flex-grow">{user.email}</span>
                    </dd>
                  </div>

                  <div className="pb-4 grid grid-cols-3 gap-4 text-indigo-600 dark:text-gray-300">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Role</dt>
                    <dd className="mt-1 flex text-sm col-span-2 mt-0">
                      <span className="flex-grow">{user.role.name}</span>
                    </dd>
                  </div>

                  <div className="pb-4 grid grid-cols-3 gap-4 text-indigo-600 dark:text-gray-300">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Sends</dt>
                    <dd className="mt-1 flex text-sm col-span-2 mt-0">
                      <span className="flex-grow">30</span>
                    </dd>
                  </div>

                  <div className="sm:pb-0 pb-4 grid grid-cols-3 gap-4 text-indigo-600 dark:text-gray-300">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">March 2023 Sends</dt>
                    <dd className="mt-1 flex text-sm col-span-2 mt-0">
                      <span className="flex-grow">30</span>
                    </dd>
                  </div>

                </dl>
              </div>

              <button type="button" className="absolute bottom-0 right-0 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                {auth.userRole >= user?.role?.accesslevel && (
                  <>
                  <Link to={`/user/edit/${user.id}`}>
                    <span className="w-18 h-7 p-5 inline-flex text-xs items-center justify-center rounded-tl-2xl rounded-br-1xl text-gray-500 bg-gray-100 hover:opacity-100 opacity-60 dark:text-white dark:bg-gray-800 overflow-hidden">
                      <PencilIcon className="w-4 h-3 mr-1" aria-hidden="true"/> Edit
                    </span>
                  </Link>
                  </>
                )}
              </button>

            </div>
            )
          }
          )  }

            </div>

            {!users &&
            <div className="m-3 ">
                <h3 className="text-red-800">No users found</h3>
            </div>
            }


            </>

    )
}
export default Users
