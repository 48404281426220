import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fabric} from "fabric"
import rect from '../../assets/images/numberplate.png'
import {saveCanvas} from "../../store/features/entry/slice";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Canvas = ({imageUrl, modifiedImageCB}) => {
    const dispatch = useDispatch()
    const canvasInStore = useSelector(state => state.entry.canvas)
    const banner = useSelector(state => state.user?.company?.settings?.postimagebanner)
    const logoImgUrl = useSelector(state => state.user?.company?.logo)
    const containerRef = useRef(null)
    const [canvas, setCanvas] = useState('');
    const setCanvasDimensions = (parentWidth, parentHeight) => {
      const newWidth = parentWidth > CANVAS_WIDTH ? CANVAS_WIDTH : parentWidth;
      const newHeight = parentHeight > CANVAS_HEIGHT ? CANVAS_HEIGHT : parentHeight;
      canvas.setDimensions({ width: newWidth, height: newHeight });
    };
    const CANVAS_WIDTH = 600;
    const CANVAS_HEIGHT = 600;
    const initCanvas = () => new fabric.Canvas('canvas', {
        preserveObjectStacking: true,
        backgroundColor: "#e3e3e3",
    })

    useEffect(() => {
        setCanvas(initCanvas())
    }, [])
    useEffect(() => {

        if (canvasInStore) {
            if (canvas) {

                canvas.setDimensions({width: 600, height: 600});
                canvas.loadFromJSON(canvasInStore, function () {
                    canvas.renderAll();
                }, (o, object) => {
                    if (o.name === "productImage") {
                        object.on('mousewheel', (options) => onMouseWheel(options))
                    }

                });
            }

        }
    }, [canvas])


    useEffect(() => {
      if (canvas) {
        canvas.setDimensions({width: 600, height: 600});
        if (!canvasInStore) {
          fabric.Image.fromURL(imageUrl, (oImg) => {
            oImg.toObject = (function (toObject) {
              return function (propertiesToInclude) {
                return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                  name: this.name
                });
              };
            })(oImg.toObject);

            oImg.set({
              name: "productImage"
            });

            oImg.on('mousewheel', (options) => onMouseWheel(options));
            canvas.insertAt(oImg, 0);
            canvas.centerObject(oImg); // <-- add this line to center the image
            convertImageToFile();
            canvas.renderAll();
          });
          if (logoImgUrl) {
            addCompanyLogo();
          }
          if (banner) {
            addCompanyBanner();
          }
        }

        canvas.on('mouse:up', async () => {
          // dispatch(setLoading(true))
          await convertImageToFile();
          // dispatch(setLoading(false))
        });
      }
    }, [canvas, canvasInStore]);


    const onMouseWheel = (options) => {
        options.e.preventDefault();
        options.e.stopPropagation();
        let scrollDirection = options.e.deltaY > 0 ? -1 : 1;

        // on what object it was scrolled?
        let target = canvas.findTarget(options.e.target);

        //updates image scale by +/- 5%

        let marioScale = target.scaleX;
        marioScale += scrollDirection * 0.05;

        let scaledWidth = target.getScaledWidth();
        let scaledHeight = target.getScaledHeight();


        //remember where was the center of the image
        let oldCenter = new fabric.Point(
            target.left + scaledWidth / 2,
            target.top + scaledHeight / 2);
        if (marioScale * scaledWidth > 100 && marioScale * scaledHeight > 105) {
            target.scale(marioScale);

            //after scale center moved, we need to re-center back to old center
            let newScaledWidth = target.getScaledWidth();
            let newScaledHeight = target.getScaledHeight();

            target.left = oldCenter.x - newScaledWidth / 2;
            target.top = oldCenter.x - newScaledHeight / 2;

            canvas.renderAll();
        }
    }
    const addRectangle = () => {

        const blurImageUrl = rect;

        fabric.Image.fromURL(blurImageUrl, (blurImg) => {
            blurImg.scaleToHeight(50)
            blurImg.scaleToWidth(200)
            const filter = new fabric.Image.filters.Blur({
                blur: 3
            });
            blurImg.filters.push(filter)
            blurImg.applyFilters();
            canvas.centerObject(blurImg)
            canvas.add(blurImg);
            convertImageToFile()
            canvas.renderAll()
        })
    }
    const deleteSelected = () => {
        const canvasObjects = canvas.getObjects()

        if (canvasObjects.length > 1) {
            canvas.getActiveObjects().forEach((obj) => {
                canvas.remove(obj)
            });
            convertImageToFile()
            canvas.discardActiveObject().renderAll()
        }


    }
    const undo = () => {
        const canvasObjects = canvas.getObjects();

        if (canvasObjects.length > 1) {
            const object = canvas.item(canvas.getObjects().length - 1);
            canvas.remove(object);
            convertImageToFile()
            canvas.renderAll()
        }

    }

const addCompanyBanner = () => {
  fabric.Image.fromURL(banner, function (banner) {
    banner.scaleToWidth(canvas.width);
    banner.top = canvas.height - banner.getScaledHeight();
    banner.left = 0;
    banner.selectable = false;
    canvas.add(banner);
    convertImageToFile();
    canvas.renderAll();
  }, { crossOrigin: 'anonymous' });
};


    const addCompanyLogo = () => {


        fabric.Image.fromURL(logoImgUrl, function (logoImg) {
            logoImg.scaleToWidth(100)
            logoImg.opacity = 0.75
            logoImg.left = CANVAS_WIDTH - 100;
            logoImg.top = 0;
            logoImg.lockScalingX = true;
            logoImg.lockScalingY = true;
            logoImg.selectable = false;
            logoImg.lockMovementX = false;
            logoImg.lockMovementY = false;

            canvas.add(logoImg);
            convertImageToFile()
            canvas.renderAll()
        }.bind(this), {
            crossOrigin: 'anonymous'
        })
    }

    const convertImageToFile = async () => {
        // modifiedImageCB
        const dataURL = canvas.toDataURL({
            format: 'png',
        });
        const blob = await (await fetch(dataURL)).blob();
        const file = new File([blob], 'fileName.jpg', {type: "image/jpeg", lastModified: new Date()});
        dispatch(saveCanvas(JSON.stringify(canvas)))
        modifiedImageCB(file)

    }

    function resizeCanvas(canvas) {
      const outerCanvasContainer = document.querySelector('.fabric-canvas-wrapper');
      //const outerCanvasContainer = containerRef.current;
      const ratio = canvas.getWidth() / canvas.getHeight();
      const containerWidth = outerCanvasContainer.clientWidth;
      const containerHeight = outerCanvasContainer.clientHeight;
      const scale = containerWidth / canvas.getWidth();
      const zoom = canvas.getZoom() * scale;

      canvas.setDimensions({ width: containerWidth, height: containerWidth / ratio });
      canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    }


    useEffect(() => {
      if (canvas) {
        window.addEventListener('resize', () => {
          resizeCanvas(canvas);
        });

        return () => {
          window.removeEventListener('resize', () => {
            resizeCanvas(canvas);
          });
        };
      }
    }, [canvas]);

    return (
        <>
        <div className="mt-10 m-auto" style={{maxWidth: '600px'}}>
         <div className="w-full border border-4 rounded-xl mx-auto mt-4 overflow-hidden">
          <div className="fabric-canvas-wrapper">
          <canvas id="canvas" className="round-lg overflow-hidden" ref={containerRef}/>
          </div>
        </div>
            <div className="w-full mx-auto">
                <div className="grid grid-flow-col auto-cols-max gap-2 flex justify-center mt-5 p-3">

                    <div>
                        <button
                            type="button"
                            disabled={logoImgUrl}
                            onClick={addCompanyLogo}
                            className ={classNames(
                                !logoImgUrl ? 'shadow-none hover:bg-none' : 'hover:border-indigo-200 ',
                                'hidden inline-flex items-center gap-x-2 float-right rounded-full border-2 shadow-sm px-4 py-2 text-sm text-gray-500 dark:text-white w-auto'
                            )}
                         >
                            Add Company logo
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={addRectangle}
                            type="button"
                            className="inline-flex items-center gap-x-2 float-right rounded-full border-2 shadow-sm px-4 py-2 text-sm text-gray-500 dark:text-white w-auto"
                        >
                            Add Rectangle
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={undo}
                            type="button"
                            className="inline-flex items-center gap-x-2 float-right rounded-full border-2 shadow-sm px-4 py-2 text-sm text-gray-500 dark:text-white w-auto"
                        >
                            Undo
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={deleteSelected}
                            type="button"
                            className="inline-flex items-center gap-x-2 float-right rounded-full border-2 shadow-sm px-4 py-2 text-sm text-gray-500 dark:text-white w-auto"
                        >
                            Delete Selected
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default Canvas
