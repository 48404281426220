import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import {useAuth} from "../../hooks/useAuth";
import { Toggle } from '../../components/dark/toggle'
import Brand from "./Brand";
import {NavLink} from "react-router-dom";

import {
    CogIcon,
    FolderIcon,
    HomeIcon,
    OfficeBuildingIcon,
    UsersIcon,
    MailIcon,
    XIcon,
    ChevronDoubleLeftIcon
} from '@heroicons/react/outline'
import MobileMenuButton from "./Mobile/MenuButton";
import Sidebar from "./Sidebar";
import ProfileDropdown from "./ProfileDropdown";
import Alert from "../UI/Alert";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../UI/Loader";
import DeactivateModal from "../UI/Modals/Deactivate";
import {IdentificationIcon} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";


const AppLayout = ({brandContainerClass, children, pageTitle}) => {

    const auth = useAuth();


    const [sidebarOpen, setSidebarOpen] = useState(false)

    const loading = useSelector(state => state.app?.loading)
    const user = useSelector(state => state.user)


    const countDownDate = parseInt(sessionStorage.getItem('xat')) * 1000
    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);
        return () => clearInterval(interval);
    }, [countDownDate])


    useEffect(()=>{
        if(countDown <= 0){
            auth.signOut()
        }
    }, [countDown])

    const navigation = [
        // {name: 'Dashboard', icon: HomeIcon, current: true, href: '/', roleLevel: 1},
        {name: 'Entries', sub: 'Latest engagements', icon: MailIcon, iconColor: '', current: false, href: '/entries', roleLevel: 1},
        {name: 'Facebook', sub: 'Facebook posts, scheduled', icon: UsersIcon, iconColor: '', current: false, href: '/facebook', roleLevel: 5},
        {name: 'Instagram', sub: 'Instagram posts, scheduled', icon: UsersIcon, iconColor: '', current: false, href: '/instagram', roleLevel: 5},
        {name: 'Users', sub: 'User management', icon: UsersIcon, iconColor: '', current: false, href: '/users', roleLevel: 5},
        {name: 'Templates', sub: 'View or create', icon: FolderIcon, iconColor: '', current: false, href: '/templates', roleLevel: 5},
        //{name: 'Company', sub: 'Company profile', icon: OfficeBuildingIcon, iconColor: '', current: false, href: `/company`, roleLevel: 80},
        {name: 'Account', sub: 'Manging billing, team, security', icon: CogIcon, iconColor: '', current: false, href: `/account`, roleLevel: 80},
        {name: 'Help Center', sub: 'FAQs and guides', icon: OfficeBuildingIcon, iconColor: '', current: false, href: `/company`, roleLevel: 80,
          children: [
                {name: 'Home', href: '/help-center', roleLevel: 5},
                {name: 'FAQs', href: '/faqs', roleLevel: 5},
                {name: 'Guides', href: '/guides', roleLevel: 5},
                {name: 'Support', href: '/support', roleLevel: 5},
            ],
          },
        {
            name: 'Settings',
            icon: CogIcon,
            iconColor: '',
            current: false,
            roleLevel: 5,
            children: [

                {name: 'Presets', href: '/settings/presets', roleLevel: 5},
                {name: 'Facebook', href: '/settings/facebook', roleLevel: 5},
                // {name: 'Instagram', href: '/settings/instagram', roleLevel: 5},
            ],
        },

    ]

    const adminNavigation = [
        // { name: 'Companies', href: '/companies', roleLevel: 80 },
        { name: 'Add Company', sub: 'Add new company', icon: OfficeBuildingIcon, iconColor: '', href: '/company/add', current: false, roleLevel: 80 },

    ]
    const userNavigation = [
        {name: 'Your Profile', href: `/user/${auth.user ? auth.user.id : ''}`, roleLevel: 1,},
        {name: 'Company Profile', href: `/company`, roleLevel: 5},
        {name: 'Settings', href: '/settings/presets', roleLevel: 5},
    ]
    return (
        <>
            <div>
                <Loader show={loading}/>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                        </Transition.Child>

                        <div className="fixed inset-0 flex z-40">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel
                                    className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white dark:bg-gray-800">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <ChevronDoubleLeftIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <Sidebar brandContainerclassName="flex-shrink-0 flex items-center px-4"
                                             navContainerclassName="mt-5 flex-1 h-0 overflow-y-auto"
                                             navclassName="px-2 space-y-1"
                                             navigation={navigation}/>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className="flex-shrink-0 w-14" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 z-20 shadow bg-white dark:shadow-gray-700 dark:bg-gray-900">

                    <div className="flex-1 flex justify-between ">
                        <div className="flex-1 flex items-center">
                        <NavLink to="/" className="p-2"><Brand containerClass={brandContainerClass}/></NavLink>
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">
                            <div className="mr-4"><Toggle/></div>
                            <div className="text-white dark:text-gray-500 m-2 mt-2 rounded rounded-md">
                             <ProfileDropdown userNavigation={userNavigation}/>
                            </div>
                            <MobileMenuButton setSidebarOpen={setSidebarOpen}/>
                        </div>
                    </div>
                </div>

                <div className="hidden md:pt-20 pr-2 md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-white dark:bg-gray-900">
                    <Sidebar brandContainerclassName="flex items-center h-16 flex-shrink-0 px-4"
                             navContainerclassName="flex-1 flex flex-col overflow-y-auto"
                             navclassName="flex-1 px-2 pt-6 space-y-1" navigation={navigation} adminNav={adminNavigation}/>
                </div>
                <div className="md:pl-64 flex flex-col">
                    <main className="flex-1 pb-5 bg-gray-100 dark:bg-gray-900">
                        <div>
                            <Alert/>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                              <h1 className="text-2xl font-semibold text-gray-900">{pageTitle}</h1>
                            </div>
                            <div>
                              <div>
                               {children}
                              </div>
                            </div>
                        </div>
                    </main>
                </div>
                <DeactivateModal/>

            </div>
        </>
    )

}

export default AppLayout
