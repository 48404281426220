import {CheckIcon} from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Step({step, stepIdx}) {
    return (
        <a href={step.href} className="group">

            <span
                className={classNames(
                    stepIdx !== 0 ? 'lg:p-2' : '',
                    'p-3 flex items-start text-sm font-medium'
                )}
            >
                      <span className="flex-shrink-0">
                        <span className={classNames(
                            step.status === 'upcoming' ? 'border-gray-300' : (step.status === 'completed' ? 'bg-blue-800 border-blue-600' : 'border-blue-600'),
                            'w-10 h-10 flex items-center justify-center border-2 rounded-full'
                        )}>

                            {step.status === "completed" ?
                                (<CheckIcon className="w-6 h-6 text-white" aria-hidden="true"/>)
                                : (<span className="text-gray-700 dark:text-gray-200">{step.id}</span>)
                            }

                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase dark:text-gray-200">{step.name}</span>
                        <span className="text-xs text-gray-500 mt-2">{step.description}</span>
                      </span>
                    </span>
        </a>
    )
}
//
// current
// <span className="w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full">
//
// completed
//     <span className="w-10 h-10 flex items-center justify-center bg-blue-800 rounded-full">
//
//     <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
