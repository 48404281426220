/* This example requires Tailwind CSS v2.0+ */
import {useEffect, useState} from 'react'
import {Switch} from '@headlessui/react'
import {useField} from "formik";
import {at} from "lodash";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SwitchField(props) {
    const {label, description, onChangeCB} = props


    const [field, meta, helpers] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error;
    const {setValue} = helpers;
    const [enabled, setEnabled] = useState(field.value)
    const handleSwitchChange = (value) => {
        setEnabled(value)
        setValue(value)
    }

    useEffect(()=>{
        if(onChangeCB){
            onChangeCB(field.value)
        }
    },[field.value, onChangeCB])
    return (
        <Switch.Group as="div" className="flex items-center justify-between">
            <Switch
                checked={field.value}
                onChange={handleSwitchChange}
                className={classNames(
                    enabled ? 'bg-blue-800' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                )}
            >
        <span
            aria-hidden="true"
            className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
        />
            </Switch>
              <span className="ml-5 flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                 {label}
                </Switch.Label>
                  {description &&
                  <Switch.Description as="span" className="text-sm text-gray-500">
                      {description}
                  </Switch.Description>}
              </span>

        </Switch.Group>
    )
}
