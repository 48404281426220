import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getCurrentMonthEntriesByUser} from "../../store/features/dashboard/thunks";
import Spinner from '../../components/UI/Spinner.js'

const formatDate = (date) => {
  const dateToFormat = new Date(date);

  const fDate = dateToFormat.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const fTime = dateToFormat.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${fDate} ${fTime}`;
};

const CurrentMonthEntriesByUser = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("d05bec50-6b20-41ff-b340-b6d11143b51f");

  useEffect(() => {
    if (userId) dispatch(getCurrentMonthEntriesByUser({userId}));
  }, [dispatch, userId]);
  
  const userEntries = useSelector((state) => state.dashboard?.currentMonthEntriesByUser);
  console.log(userEntries, 'user entries')
  // const sortedEntries = userEntries?.length ? userEntries.sort((a,b) => new Date(b.createdon) - new Date(a.createdon)) : [];

  const date = new Date();
  const dateOptions = {year: "numeric", month: "long"};
  const formattedDate = date.toLocaleString("en-GB", dateOptions);


  return (
    <div className="sm:col-span-2 lg:col-span-3 bg-opacity-30 border border-gray-200 shadow bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100 dark:border-gray-700  flex flex-col flex-auto bg-card rounded-2xl overflow-hidden">
      <div className="px-6 pt-5">
        <div className="flex justify-between items-center">
          <h1 className="mb-2 text-2xl">Entries - {formattedDate}</h1>
        </div>
        <h3 className="dark:text-gray-300 text-gray-600">A list of all the customers in your account for {formattedDate}</h3>
      </div>

      <h2 className="px-6 py-5 text-xl">{formattedDate}</h2>

     

            <div className="grid grid-cols-1 gap-2 m-4 mt-0">
              {userEntries?.length ? 
                userEntries.map(({id, firstname, lastname, photo, email, createdon , product}) => (
                  <div
                    key={id}
                    className="relative flex items-center space-x-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center w-14 h-14 rounded-full"  style={{backgroundImage: `url(${photo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}></div>
                    <div className="min-w-0 flex-1 ">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className=" font-medium text-gray-800 dark:text-gray-100">
                        {firstname + ' ' + lastname}
                      </p>
                      <p className="font-medium text-gray-800 dark:text-gray-100">
                        {email}
                      </p>
                    </div>

                    <div className="w-1/2 flex justify-between">
                      <span className="block text-gray-800 dark:text-gray-200 font-bold">
                        {product}
                      </span>
                      <span className="block text-gray-800 dark:text-gray-200 font-bold">
                        {formatDate(createdon)}
                      </span>
                    </div>
                  </div>
                )) : <Spinner />}
            </div>
       

    </div>
  );
};

export default CurrentMonthEntriesByUser;
