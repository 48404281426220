import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form} from 'formik';
import {getRoles} from "../../store/features/role";
import {getUsers, updateUser} from "../../store/features/user/thunks";
import Loader from "../../components/UI/Loader";
import PersonalDetailsForm from "../../components/Forms/PersonalDetailsForm";
import {SelectField} from "../../components/UI/FormControls";
import {find} from "lodash";
import {useAuth} from "../../hooks/useAuth";
import {setAlert, setLoading} from "../../store/features/app";
import {getFormattedRoles} from "../../helpers"

const EditUser = () => {
    const formInitialValues = {
        firstname: "",
        lastname: "",
        email: "",
        title: "",
        roleid: "",
    }
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required(`First name is required`),
        lastname: Yup.string()
            .required(`Last name is required`),
        email: Yup.string()
            .email(`Enter valid email address`)
            .required(`Email Address is required`),
        roleid: Yup.string()
            .required('Role is required')
    });

    const auth = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const companyId = useSelector(state => state.user.company?.id)
    const [selectedUser, setSelectedUser] = useState(null)
    const users = useSelector(state => state.user?.list)

    const [formattedRoles, setFormattedRoles] = useState([])
    const roles = useSelector(state => state.role?.list)

    const {id} = useParams()


    useEffect(() => {
        (async () => await dispatch(getRoles()))()
    }, [dispatch])

    useEffect(() => {

        if (roles) {
            setFormattedRoles(getFormattedRoles(auth.userRole, roles))
        }

    }, [roles])

    useEffect(() => {
        dispatch(setLoading(true))
        if (!id) {
            auth.userRole > 1 ? navigate(`/users`) : navigate('/dashboard')
        }

        if (auth.userRole > 1 && !users && companyId) {

            dispatch(getUsers({companyId})).then(res => {

                setSelectedUser(find(res.payload.data, (u) => {
                    return u.id === id
                }))
                dispatch(setLoading(false))
            })
        } else if (auth.userRole > 1 && users) {

            setSelectedUser(find(users, (u) => {
                return u.id === id
            }))
            dispatch(setLoading(false))
        }

        if (auth.userRole === 1) {
            setSelectedUser(auth.user)
            dispatch(setLoading(false))
        }
    }, [dispatch, auth.userRole, users, companyId])

    const handleSubmit = (values, actions) => {
        dispatch(setLoading(true))
        actions.setTouched({});
        actions.setSubmitting(false);
        const payload = {...values};

        payload.userId = auth.user.id

        dispatch(updateUser({id: selectedUser.id, data: payload})).then(res => {

            if (res.payload.error) {
                dispatch(setAlert({
                        show: true,
                        type: "error",
                        content: res.payload.error
                    }
                ));
                dispatch(setLoading(false))
            } else {
                dispatch(setAlert({
                        show: true,
                        type: "success",
                        content: "Successfully updated user"
                    }
                ));

                (async () => await dispatch(getUsers({companyId})))()
                dispatch(setLoading(false))
                navigate(`/user/${id}`)
            }
        });
    }
    return (
        <>
            <Loader/>
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({errors, touched, isSubmitting, setFieldValue}) => {

                    useEffect(() => {

                        if (selectedUser) {
                            setFieldValue('firstname', selectedUser.firstname)
                            setFieldValue('lastname', selectedUser.lastname)
                            setFieldValue('email', selectedUser.email)
                            setFieldValue('title', selectedUser.title ? selectedUser.title : "")
                            setFieldValue('roleid', selectedUser.role.id ? selectedUser.role.id : "")

                        }
                    }, [selectedUser])
                    return (
                        <Form id="editUser" className="mx-auto max-w-7xl px-6 sm:px-8 mt-10 mb-20">
                            <div className="overflow-hidden rounded-lg">
                            <div>
                              <h3 className="text-4xl sm:text-4xl text-slate-800 dark:text-gray-100 font-extrabold leading-tight tracking-tight">User Details</h3>
                              <p className="mt-1 text-sm text-gray-500 pb-5">Please provide the following information.</p>
                            </div>
                                <div className="">
                                    <PersonalDetailsForm showTitleField={true} disableEmail={true}/>
                                    <div className="col-span-6 sm:col-span-3 mt-5">
                                        {formattedRoles &&
                                        <SelectField name="roleid"
                                                     disabled={auth.userRole === 1}
                                                     label="Role"
                                                     data={formattedRoles}
                                                     className={auth.userRole === 1 ? 'bg-gray-300' : ""}/>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-6 gap-4 justify-items-stretch mt-5">
                                <div className="col-start-1 col-end-3">
                                    <button type="submit"
                                            className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
                                            Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )

                }}

            </Formik>
        </>

    )
}

export default EditUser
