
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

import * as Yup from "yup";
import {Form, Formik} from "formik";

import {getRoles} from "../../store/features/role";
import {setAlert, setLoading} from "../../store/features/app";
import {getFormattedRoles} from "../../helpers"
import PersonalDetailsForm from "../../components/Forms/PersonalDetailsForm";
import {SelectField} from "../../components/UI/FormControls";
import {addUser, getUsers} from "../../store/features/user/thunks";
import {useAuth} from "../../hooks/useAuth";

const AddUser = () => {
    const formInitialValues = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        title: "",
        roleid: "",
    }
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required(`First name is required`),
        lastname: Yup.string()
            .required(`Last name is required`),
        email: Yup.string()
            .email(`Enter valid email address`)
            .required(`Email Address is required`),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!~)(^#%*?&])[A-Za-z\d@$!~)(^#%*?&]{8,}$/,
                "Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
            ),
        roleid: Yup.string()
            .required('Role is required')
    });

    const dispatch = useDispatch()
    const auth = useAuth()

    const companyId = useSelector(state => state.user.company?.id)
    const roles = useSelector(state => state.role?.list)

    const [formattedRoles, setFormattedRoles] = useState([])

    useEffect(() => {
        dispatch(setLoading(true));
        (async () => {
            await dispatch(getRoles())
            dispatch(setLoading(false));
        })()

    }, [dispatch])

    useEffect(() => {
        if (roles) {
            setFormattedRoles(getFormattedRoles(auth.userRole, roles))
        }
    }, [roles])

    const handleSubmit = (values, actions) => {

        dispatch(setLoading(true));
        actions.setTouched({});
        actions.setSubmitting(true);
        values.company = companyId;

        dispatch(addUser({data: values})).then(res => {

            if (res.payload.error) {
                dispatch(setAlert({
                    show: true,
                    type: "error",
                    content: res.payload.error
                }));
                dispatch(setLoading(false));
            } else {
                actions.resetForm()
                dispatch(setAlert({
                    show: true,
                    type: "success",
                    content: "Successfully created user"
                }));

                (async () => {
                    await dispatch(getUsers({companyId}))
                    dispatch(setLoading(false));
                })()
            }
        });
        actions.setSubmitting(false);
    }
    return (
        <>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form id="addUser">
                            <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6">
                                    User Details
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <PersonalDetailsForm showTitleField={true} showPasswordField={true}/>
                                    <div className="col-span-6 sm:col-span-3 mt-5">
                                        {formattedRoles && <SelectField name="roleid" label="Role" data={formattedRoles}/>}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-6 gap-4 justify-items-stretch mt-5">
                                <div className="col-start-1 col-end-3">
                                    <button type="submit"
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        Add user
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>



        </>
    )
}

export default AddUser
