// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect , useState} from "react";
import {  useDispatch } from "react-redux";
import { getLeaderboard } from "../../store/features/dashboard/thunks";
import { setLoading } from "../../store/features/app";
// import {
//   DocumentDuplicateIcon,
//   CheckCircleIcon,
//   ChevronRightIcon,
//   EnvelopeIcon,
// } from "@heroicons/react/solid";

function getFirstLetter(str) {
  if (str.length > 0) {
    return str.charAt(0);
  }
  return '';
}

// function groupNotificationsByCreator(notifications) {
//   const groupedNotifications = {};

//   notifications.forEach((notification) => {
//     const creator = notification.entry.createdby;
//     const firstname = notification.entry.firstname;
//     const lastname = notification.entry.lastname;
//     const email = notification.entry.email;

//     if (!groupedNotifications[creator]) {
//       groupedNotifications[creator] = { count: 1, firstname, lastname, email };
//     } else {
//       groupedNotifications[creator].count++;
//     }
//   });

//   return groupedNotifications;
// }


const LeaderBoard = () => {
  const dispatch = useDispatch();


  // const notifications = useSelector((state) => state.notifications?.list);
  const companyId = "28d5a1f1-39ff-4fa7-b33a-14e4e440143d";
  // const companyId = useSelector((state) => state.user?.company?.id);
  const [leaderboard, setLeaderboard] = useState([]);
  useEffect(() => {
    if (dispatch && companyId) {
      dispatch(setLoading(true));
      dispatch(getLeaderboard({ companyId })).then((res) => {
        setLeaderboard(res.payload.data)
        dispatch(setLoading(false));
      });
    }
  }, [dispatch, companyId]);


  // Check that notifications is not null or undefined
  // if (!notifications) {
  //   return null;
  // }
  
  // const groupedNotifications = groupNotificationsByCreator(notifications);
  
  // Convert the object to an array and sort by count
  // const sortedNotifications = Object.entries(groupedNotifications).map(
  //   ([creator, { count }]) => ({ creator, count })
  //   ).sort((a, b) => b.count - a.count);
    
    // Limit the list to 10 items
    // const topTenNotifications = leaderboard.slice(0,0);
     
    console.log(leaderboard, 'notifications')
  return (
    <>
      <div className="sm:col-span-2 lg:col-span-3 bg-white bg-opacity-30 border border-gray-200 shadow bg-gray-100 dark:bg-gray-700 dark:border-gray-700 shadow-sm flex flex-col flex-auto bg-card rounded-2xl overflow-hidden">
        <div className="flex flex-col text-gray-800 pl-8 pt-4 pb-5 dark:text-white">
          <div className="mr-4 text-2xl md:text-3xl font-semibold tracking-tight leading-7">Leader board</div>
          <div className="font-medium text-secondary">Top 10 team members</div>
        </div>

        <div className="grid grid-cols-1 gap-2 m-4 mt-0">
          {leaderboard?.length ? leaderboard.map(({ id, name, email, cnt }) => (
            <div
              key={id}
              className="relative fade-in flex items-center space-x-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
              <div className="flex-shrink-0 flex items-center justify-center w-10 h-10 rounded-full bg-yellow-400">
              {getFirstLetter(name.split(' ')[0])}{getFirstLetter(name.split(' ')[1])}
              </div>
              <div className="min-w-0 flex-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-800 dark:text-gray-100">{name}</p>
                  <p className="text-sm font-medium text-gray-800 dark:text-gray-100">{email}</p>
              </div>
              <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-gray-400">
                <span className="block text-gray-800 dark:text-gray-200 font-bold">{cnt}</span>
              </div>
            </div>
          )) : [0,1,2,3,4,5,6,7,8,9].map((int) => (
            <div key={int} className="relative flex items-center mb-2 space-x-3 rounded-lg bg-gray-300 dark:bg-gray-500 h-20 shadow-sm  skeleton-loader"></div>))
          }
          </div>
      </div>
    </>
  );
};

export default LeaderBoard;
