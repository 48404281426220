import {createSlice} from '@reduxjs/toolkit'
import {addEntry, getEntries, sendEmail, sendSMS, getEntryById, postToFBPage, } from './thunks'

const entryInitialState = {
    id: "",
    name: "",
    logo: "",
    website: "",
    addresslineone: "",
    addresslinetwo: "",
    city: "",
    state: "",
    postCode: "",
    contactnumber: "",
    active: true,
    user: {
        id: "",
        firstname: "",
        lastname: "",
        email: "",
    },
    list: null,
    entriesByUserList: [],
    isLoading: false,
    stepIndex: 0,
    steps: [
        {
            id: '01',
            name: 'Upload Photos',
            description: 'Upload photos with max size of 1MB',
            href: '#',
            status: 'current'
        },
        {
            id: '02',
            name: 'Edit Image',
            description: 'Crop image and block number plate',
            href: '#',
            status: 'upcoming'
        },
        {
            id: '03',
            name: 'Entry form',
            description: 'Please fill in the information',
            href: '#',
            status: 'upcoming'
        },
        {
            id: '04',
            name: 'Preview & Submit',
            description: 'Preview of the information',
            href: '#',
            status: 'upcoming'
        },
    ],
    canvas: '',
    uploadedimageurl: "",
    editedimageurl: "",
    topEntries: []

}
const entrySlice = createSlice({
    name: 'entry',
    initialState: entryInitialState,
    reducers: {
        saveCanvas(state, action) {
            state.canvas = action.payload
        },
        removeCanvas(state) {
            state.canvas = ''
        },
        changeEntryStepStatus(state, action) {
            state.stepIndex = action.payload
            state.steps = state.steps.map((step, index) => {
                if (action.payload > index) {
                    return {
                        ...step,
                        status: "completed"
                    }
                } else if (action.payload < index) {
                    return {
                        ...step,
                        status: "upcoming"
                    }
                } else {
                    return {
                        ...step,
                        status: "current"
                    }
                }
            })
        }
    },
    extraReducers: {

        [getEntryById.fulfilled]: (state, {payload}) => {

            const data = payload.data[0]

            return {...state, ...data}
        },
        [getEntries.fulfilled]: (state, {payload}) => {
            state.list = payload
            state.isLoading = false
        },
        [addEntry.fulfilled]: (state,{payload}) => {
            return {...state, ...payload.data[0]}
        },
     
    
    },
})
export const {changeEntryStepStatus, saveCanvas, removeCanvas} = entrySlice.actions
export default entrySlice.reducer