import {createAsyncThunk} from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";

export const getEntryById = createAsyncThunk(
    'entry/getEntryById',
    async (payload) => {
        return await fetchAPI(`/api/entry/byId`, payload)
    }
)

export const getEntries = createAsyncThunk(
    'entry/getEntries',
    async (_, {getState}) => {
        return await fetchAPI(`/api/entry/list`)
    }
)

export const updateEntry = createAsyncThunk(
    'entry/updateEntry',
    async (payload) => {
        return await fetchAPI(`/api/entry/update`, payload)
    }
)

export const addEntry = createAsyncThunk(
    'entry/addEntry',
    async (payload) => {
        return await fetchAPI(`/api/entry/add`, payload)
    }
)

export const sendEmail = createAsyncThunk(
    'entry/sendEmail',
    async (payload) => {
        return await fetchAPI(`/api/entry/sendEmail`, payload)
    }
)
export const sendSMS = createAsyncThunk(
    'entry/sendSms',
    async (payload) => {
        return await fetchAPI(`/api/entry/sendSms`, payload)
    }
)

export const getEntriesByMonthWithCount = createAsyncThunk(
    'entry/entriesByMonthWithCount',
    async (payload) => {
        return await fetchAPI(`/api/entry/entriesByMonthWithCount`, payload)
    }
)

