import {InputField, SelectField} from "../UI/FormControls";
import React from "react";
import states from '../../data/states'

const AddressForm = () => {

    return (
        <>
            <div className="col-span-6">
                <InputField name="addresslineone" label="Address Line One"/>
            </div>
            <div className="col-span-6">
                <InputField name="addresslinetwo" label="Address Line Two"/>
            </div>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <InputField name="city" label="City"/>
            </div>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <SelectField name="state" label="State / Province" data={states}/>
            </div>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <InputField name="postcode" label="ZIP / Postal code"/>
            </div>
        </>
    )
}

export default AddressForm;
