import {useCallback, useEffect, useState} from "react";
import {useFbSdk} from '../../hooks/useFbSdk'
import {getPageAccessToken, removePageAccessToken} from "../../store/features/facebook/thunks";
import {setAlert, setLoading} from "../../store/features/app";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/UI/Loader";

const FacebookSettings = () => {

    const dispatch = useDispatch()
    const isFbSDKInitialized = useFbSdk();

    const company = useSelector(state => state.user?.company)
    const userId = useSelector(state => state.user?.id)

    const [fbUserAccessToken, setFbUserAccessToken] = useState();
    const [declinedPermissions, setDeclinedPermissions] = useState(null)
    useEffect(() => {
        if (isFbSDKInitialized) {
            window.FB.getLoginStatus((response) => {
                setFbUserAccessToken(response.authResponse?.accessToken);
            });
        }
    }, [isFbSDKInitialized]);
    useEffect(() => {
        console.log("company", company)
    }, [company])

    const FbLogout = () => {
        window.FB.logout(() => {
            setFbUserAccessToken(null);
            setDeclinedPermissions(null);
            dispatch(setAlert({
                    show: true,
                    type: "success",
                    content: "Successfully un-authorised app to your page."
                }
            ));
        });
    }

    const removePageAccessTokenFromDB = () => {
        const payload = {
            settingsId: company?.settings.id,
            userId,
            pageaccesstoken:''
        }
        dispatch(removePageAccessToken({data: payload})).then(res => {
            if (res.payload.error) {
                dispatch(setAlert({
                        show: true,
                        type: "error",
                        content: res.payload.error
                    }
                ));
                dispatch(setLoading(false))
            } else {
                dispatch(setLoading(false))
            }
        })
    }
    const generatePageAccessToken = (facebookpageid, shortliveduseraccesstoken) => {
        const payload = {
            settingsId: company?.settings.id,
            userId,
            facebookpageid,
            shortliveduseraccesstoken,
        }
        
        dispatch(getPageAccessToken({data: payload})).then(res => {
            console.log(res, 'client page access token res')
            if (res.payload.error) {
                dispatch(setAlert({
                        show: true,
                        type: "error",
                        content: res.payload.error
                    }
                ));
                dispatch(setLoading(false))
            } else {
                dispatch(setAlert({
                        show: true,
                        type: "success",
                        content: "Successfully authorised app to your page."
                    }
                ));
                dispatch(setLoading(false))
            }
        })
        console.log(company.settings, 'settings after authorise')
    }

    const getFbPages = () => {
        window.FB.api('/me/accounts', function (response) {
            generatePageAccessToken(response.data[0].id, response.data[0].access_token)
        })
    }

    const getPermissions = () => {
        window.FB.api('/me/permissions', function (response) {
            const permissions = response.data
            const declinedPerms = permissions.filter(permission => permission.status === 'declined')

            if (declinedPerms.length > 0) {
                setDeclinedPermissions(declinedPerms)
            } else {
                getFbPages()
            }
        })
    }

    const handleAuthorise = () => {
        dispatch(setLoading(true))
        window.FB.login((response) => {
            if (response.status === "connected") {
                setFbUserAccessToken(response.authResponse.accessToken);
                getPermissions()
            }
        }, {
            // scope with instagram permissions
            // scope: 'public_profile,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_manage_posts,instagram_basic,pages_show_list',
            scope: 'public_profile,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_manage_posts',
            return_scopes: true,

        });
    };

    const handleUnAuthorise = () => {
        dispatch(setLoading(true))
        window.FB.api('/me/permissions', "DELETE", function (response) {
            FbLogout()
            removePageAccessTokenFromDB()
            dispatch(setLoading(false))
        })
    }

    const handleReAuthoriseDeclinedPermissions = () => {
        console.log(declinedPermissions, typeof declinedPermissions)
        if (declinedPermissions.length > 0) {
            const reRequestScopes = declinedPermissions.map(permission => permission.permission)
            console.log("reRequestScopes", reRequestScopes)
            window.FB.login((response) => {
                console.log("logInToFBrerere", response)
                const permissions = response.data
                const declinedPerms = permissions.filter(permission => permission.status === 'declined')
                if (declinedPerms.length > 0) {
                    window.FB.logout(() => {
                        setFbUserAccessToken(null);
                        setDeclinedPermissions(null);
                    });
                }
            }, {
                scope: reRequestScopes.join(','),
                auth_type: 'rerequest',
                return_scopes: true
            });
        }
    }

    return (
        <>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 m-10">
              <div className="bg-white overflow-hidden rounded-lg">
                <div className="px-4 pt-5 pb-3 sm:px-6">
                    <h3 className="font-medium leading-tight text-lg mt-0 mb-2">Facebook</h3>
                    <p>We need all the following permissions to allow Engagr app to post to your facebook page.</p>
                    <ul className="text-sm pt-3">
                        <li className="p-1">Create and manage content on your Page</li>
                        <li className="p-1">Read content posted on the Page</li>
                        <li className="p-1">Manage accounts, settings and webhooks for a Page</li>
                        <li className="p-1">Show a list of the Pages you manage</li>
                    </ul>
                    {declinedPermissions && <div className="p-4 m-4">
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">Missing few permissions. Please
                                        click re-authorise button to authorise missing permissions</h3>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="divide-y divide-dashed">
                    <div className="px-4 mb-4 sm:px-6 sm:pb-6">
                        {!fbUserAccessToken && <button type="button"
                                                       onClick={handleAuthorise}
                                                       className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
                            Authorise
                        </button>}
                        {fbUserAccessToken && <button type="button"
                                                      onClick={handleUnAuthorise}
                                                      className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
                            UnAuthorise
                        </button>}

                        {declinedPermissions && <button type="button"
                                                        onClick={handleReAuthoriseDeclinedPermissions}
                                                        className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
                            ReAuthorise Missing Permissions
                        </button>}
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}
export default FacebookSettings
