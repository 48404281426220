import fetch from "node-fetch";
export const fetchAPI = async (URL, payload ) =>{

    return await fetch(URL, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(async response => {
        return await response.json()})
        .then(data => {
            return data
        })
        .catch((error) => {
            return error;
        });
}