import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getUsers} from "../../store/features/user/thunks";
import {useDispatch, useSelector} from "react-redux";
import {find} from 'lodash'
import {useAuth} from '../../hooks/useAuth'

export default function UserProfile() {
    const navigate = useNavigate();
    const auth = useAuth()
    const dispatch = useDispatch()
    const [selectedUser, setSelectedUser] = useState(null)
    const user = useSelector(state => state.user)
    const users = useSelector(state => state.user?.list)

    const userRole = auth.userRole
    const {id} = useParams();
    useEffect(() => {
        if (!id) {
            auth.userRole > 1 ? navigate(`/users`) : navigate('/dashboard')
        }

        if (auth.userRole > 1 && !users) {
            dispatch(getUsers({companyId: user.company.id})).then(res => {
                setSelectedUser(find(res.payload.data, (u) => {
                    return u.id === id
                }))
            })
        } else if (auth.userRole > 1 && users) {
            setSelectedUser(find(users, (u) => {
                return u.id === id
            }))
        }

        if (auth.userRole === 1) {
            setSelectedUser(user)
        }
    }, [dispatch, userRole])

    return (
        <>
            {selectedUser &&
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20">
           <div className="mb-10 overflow-hidden bg-white p-6 pb-0 rounded-lg">
                <div>

                <div className="flex items-center space-x-4 mb-5">
                   <div className="flex-shrink-0">
                       <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <span className="font-medium text-gray-600 dark:text-gray-300">JL</span>
                      </div>
                     </div>
                   <div className="flex-1 min-w-0">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">{selectedUser.firstname} {selectedUser.lastname}</h3>
                    <p className="max-w-auto text-sm text-gray-400">Personal details and role.</p>
                   </div>
                </div>

                    <dl className="sm:divide-y sm:divide-gray-200 border-t border-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedUser.email}</dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Title</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedUser.title}</dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Role</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedUser.role.name}</dd>
                        </div>
                        <div className="sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">
                                <button
                                    type="button"
                                    onClick={() => navigate(`/user/edit/${id}`)}
                                    className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none"
                                   >
                                    Edit Profile
                                </button>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>
          </div>
            }
        </>
    )
}
