//TODO :: find way to change background colour or re-render on state change
import {Transition} from '@headlessui/react'
import {CheckCircleIcon, ExclamationCircleIcon, XCircleIcon, XIcon} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setAlert} from "../../store/features/app";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Alert = () => {
    const alert = useSelector(state => state.app?.alert)
    const [colour, setColour] = useState(null)
    const dispatch = useDispatch()
    const renderIcon = () => {
        switch (alert.type) {
            case "success":
                !colour && setColour("green")
                return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>
            case "warning":
                !colour && setColour("yellow")
                return <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true"/>
            case "error":
                !colour && setColour("red")
                return <XCircleIcon className="h-6 w-6 text-rose-400" aria-hidden="true"/>
            default:

        }
    }

    // useEffect(() => {
    //     if (dispatch) {
    //         setTimeout(() => {
    //             dispatch(setAlert({show: true, type: "error", content: "asdadsasdd"}))
    //             setColour(null)
    //         }, "1000")
    //     }
    // }, [dispatch])

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                dispatch(setAlert({show: false, type: "", content: ""}))
                setColour(null)
            }, "15000")
        }
    }, [alert.show])
    return (
        <Transition show={alert.show}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
        >
            <div className={classNames(
                colour === "green" ? "bg-green-50 border-green-400" : '',
                colour === "yellow" ? "bg-yellow-50 border-yellow-400" : '',
                colour === "red" ? "bg-red-50 border-red-400" : '',
                `border-l-4  p-4 mx-4 mb-5`
            )

            }>
                <div className="flex">
                    <div className="flex-shrink-0">
                        {renderIcon()}
                    </div>
                    <div className="ml-3">
                        <p className={
                            classNames(
                                colour === "green" ? "text-green-700" : '',
                                colour === "yellow" ? "text-yellow-700" : '',
                                colour === "red" ? "text-red-700" : '',
                                `text-sm`
                            )
                        }
                        >
                            {alert.content}
                        </p>
                    </div>

                    <div className="ml-auto pl-3">
                        <div className="-mx-1.5 -my-1.5">
                            <button
                                type="button"
                                onClick={()=> dispatch(setAlert({show: false, type: "", content: ""}))}
                                className={
                                    classNames(
                                        colour === "green" ? "bg-green-50 text-green-500 hover:bg-green-100" : '',
                                        colour === "yellow" ? "bg-yellow-50 text-yellow-500 hover:bg-yellow-100" : '',
                                        colour === "red" ? "bg-red-50 text-red-500 hover:bg-red-100" : '',
                                        `inline-flex rounded-md p-1.5 focus:outline-none`
                                    )
                                }
                            >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}

export default Alert