import { createAsyncThunk } from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";


export const getCompanies = createAsyncThunk(
    'company/getCompanies',
    async (_,{getState}) => {
        const state = getState();
        return await fetchAPI(`/api/company/list`, {roleName: state.user.role.name})
    }
)

export const getCompanyById = createAsyncThunk(
    'company/getCompanies',
    async (payload) => {
        return await fetchAPI(`/api/company/byId`, payload)
    }
)

export const updateCompany = createAsyncThunk(
    'company/updateCompany',
    async (payload) => {
        return await fetchAPI(`/api/company/update`, payload)
    }
)

export const addCompany = createAsyncThunk(
    'company/addCompany',
    async (payload) => {
        return await fetchAPI(`/api/company/add`, payload)
    }
)