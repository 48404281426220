import React from 'react';
import {at} from 'lodash';
import {useField} from 'formik';


const SelectField = (props) => {
    const {label,name, data, className, ...rest} = props;
    const [field, meta] = useField(props);

    const {value: selectedValue} = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;

    function renderHelperText() {
        return <p className="text-rose-500">{error}</p>;
    }

    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                {label}
            </label>
            <select {...field} {...rest} value={selectedValue ? selectedValue : ''}
                    className ={`mt-1 block w-full py-2 px-3 border border-4 border-gray-300 bg-white rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className?className:''}`}
            >
                {data.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            {isError ? renderHelperText() : ""}

        </div>
    );
}

export default SelectField;
