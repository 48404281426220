import {createAsyncThunk} from '@reduxjs/toolkit'
import {fetchAPI} from "../../../helpers/fetchAPI";

export const getUsers = createAsyncThunk(
    'user/getUsers',
    async (payload) => {
        return await fetchAPI(`/api/user/list`, payload)
    }
)

export const getUserById = createAsyncThunk(
    'user/getUserById',
    async (payload) => {
        return await fetchAPI(`/api/user/byId`, {id: payload})
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (payload) => {
        return await fetchAPI(`/api/user/update`, payload)
    }
)

export const addUser = createAsyncThunk(
    'user/addUser',
    async (payload) => {
        return await fetchAPI(`/api/user/add`, payload)
    }
)
export const updateCompany = createAsyncThunk(
    'user/updateCompany',
    async (payload) => {
        return await fetchAPI(`/api/company/update`, payload)
    }
)

export const updateSettings = createAsyncThunk(
    'setting/updateSettings',
    async (payload,{getState}) => {

        const state = getState();
        payload.settingsId = state.user?.company?.settingsid
        payload.userId = state.user?.id

        return await fetchAPI(`/api/settings/update`, payload)
    }
)

// export const getPageAccessToken = createAsyncThunk(
//     'setting/getPageAccessToken',
//     async (payload) => {
//         return await fetchAPI(`/api/settings/generate-pat`, payload)
//     }
// )

// export const removePageAccessToken = createAsyncThunk(
//     'setting/removePageAccessToken',
//     async (payload) => {
//         return await fetchAPI(`/api/settings/remove-pat`, payload)
//     }
// )

