import templateImage from "../../assets/images/template.png";
import { timeAgo } from "../../utils/time.js";

export const EntriesByMonthWithCount = ({entriesByMonth}) => {
  
    return (
      <>
        {entriesByMonth.length === 0 ? 
           [0,1,2,3,4,5,6,7,8,9].map((int) => (
            <div key={int} className="relative flex items-center mb-2 space-x-3 rounded-lg bg-gray-300 dark:bg-gray-500 h-20 shadow-sm  skeleton-loader"></div>))
         :entriesByMonth.map(({month_name, entries, entry_count}) => (
        <div className="mb-4 border border-gray-50 rounded-lg bg-white dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700"  key={month_name}>
  
          <div className="ml-8 sm:ml-5 mt-3 relative"><time className="text-lg font-semibold text-gray-900 text-amber-500">{month_name}</time>
          <div className="flex items-center justify-center w-10 h-10 mr-2 rounded-full border-2 border-gray-400 absolute top-0 right-5 sm:right-0">
            <span className="block text-gray-800 dark:text-gray-200 font-bold">{entry_count}</span>
          </div>
          </div>
            <ol className="mt-3 divide-y divider-gray-100 dark:divide-gray-900 divide-y-4">
              {entries.map((entry) => (
                <li key={entry.id}>
                <a href={`/entry/${entry.id}`} className="block hover:bg-gray-50 dark:hover:bg-gray-900">
                  <div className="flex items-center px-8 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="flex-shrink-0">
                        <img src={entry.photo ? entry.photo : templateImage} alt={entry.name}
                           className="h-16 w-16 rounded-full flex items-center justify-center ring-4 ring-white dark:ring-gray-900"/>
                      </div>
                      <div className="min-w-0 flex-1 px-4 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 md:gap-4">
                        <div>
                          <div className="text-sm font-medium text-sky-900 dark:text-white">{entry.username}</div>
                          <div className="flex items-center text-sm">
                            <span className="truncate dark:text-white">{entry.email}</span>
                          </div>
                          <time className="block sm:hidden text-xs text-gray-400" dateTime="2020-01-07">{timeAgo(entry.createdon)}</time>
                        </div>
                        <div className="hidden xl:block">
                        <div className="flex flex-wrap items-center text-sm text-gray-500 dark:text-gray-400">
                          <div className="mr-1">Product:</div>
                          <div className="font-medium text-sky-900 dark:text-white">{entry.product}</div>
                        </div>
                        </div>
  
                        <div className="hidden lg:block">
                          <div>
                            <p className="text-sm text-gray-900 dark:text-gray-300">
                              <span className="mr-1">Created on</span>
                              <time dateTime="2020-01-07">{timeAgo(entry.createdon)}</time>
                            </p>
                            <p className="text-sm text-gray-900 dark:text-gray-300">
                              <span className="mr-1">Created by</span>
                              <time dateTime="2020-01-07">{entry.createdby}</time>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </a>
                </li>
              ))}
            </ol>
          </div>
        ))}
      </>
    );
  };