import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import Stepper from "../../components/UI/Stepper/Stepper";
import {
  InputField,
  SelectField,
  UploadFile,
} from "../../components/UI/FormControls";
import RadioGroupField from "../../components/UI/FormControls/RadioGroupField";
import SwitchField from "../../components/UI/FormControls/SwitchField";

import Canvas from "../../components/Entry/Canvas";
import PersonalDetailsForm from "../../components/Forms/PersonalDetailsForm";
import AddressForm from "../../components/Forms/AddressForm";
import EntryPreview from "../../components/Entry/EntryPreview";

import formInitialValues from "./formInitialValues";
import validationSchema from "./validationSchema";
import leadOptions from "../../data/leads";

import {uploadToSB} from "../../helpers/sbStorage";

import {setAlert, setLoading} from "../../store/features/app";
import {
  changeEntryStepStatus,
  removeCanvas,
} from "../../store/features/entry/slice";
import {addEntry, sendEmail, sendSMS} from "../../store/features/entry/thunks";
import {
  postToFBPage,
  getPageAccessToken,
} from "../../store/features/facebook/thunks";
import {getTemplateById} from "../../store/features/template/thunks";
import {addNotification} from "../../store/features/notifications/thunks";

import {mergeTagsToHtml} from "../../helpers";
import {ExclamationIcon} from "@heroicons/react/solid";
import {findIndex} from "lodash";
import FacebookPostOptions from "../../components/UI/FacebookPostOptions";

import {useFbSdk} from "../../hooks/useFbSdk";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const fbPostOptions = [
  {id: "instant-post", label: "Instant post"},
  {id: "schedule-post", label: "Schedule post"},
];
const hoursToDelayOptions = [
  {value: "", label: "Select Hours"},
  {value: "1", label: "1"},
  {value: "2", label: "2"},
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
];

const conditions = [
  {id: "new", label: "New"},
  {id: "used", label: "Used"},
  {id: "demo", label: "Demo"},
];
const AddEntry = () => {
  const dispatch = useDispatch();

  // const isFbSDKInitialized = useFbSdk();
  // const [fbUserAccessToken, setFbUserAccessToken] = useState();
  // const [fbPageAccessToken, setFbPageAccessToken] = useState();

  const user = useSelector((state) => state.user);
  const entry = useSelector((state) => state.entry);
  const currentStepIndex = useSelector((state) => state.entry.stepIndex);
  const settings = useSelector((state) => state.user?.company?.settings);
  const company = useSelector((state) => state.user?.company);

  console.log(company, "company in add entry");

  const [canAddEntry, setCanAddEntry] = useState(false);

  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [finalImageUrl, setFinalImageUrl] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  const [formValues, setFormValues] = useState(null);

  const [submitEntryStatus, setSubmitEntryStatus] = useState("pending");

  const [template, setTemplate] = useState(null);
  const [mergeTags, setMergeTags] = useState(null);
  const [emailcontent, setEmailcontent] = useState(null);
  const [sendEmailStatus, setSendEmailStatus] = useState("pending");

  const [smscontent, setSmscontent] = useState(null);
  const [sendSmsStatus, setSendSmsStatus] = useState("pending");

  const [facebookContent, SetFacebookContent] = useState(null);
  const [postOnFBStatus, setPostOnFBStatus] = useState("pending");

  const [showScheduler, setShowScheduler] = useState(false);
  const [postOnFacebook, setPostOnFacebook] = useState(false);

  const [entryProcessed, setEntryProcessed] = useState(false);
  const [notifications, setNotifications] = useState({});

  const [showSubmitStatus, setShowSubmitStatus] = useState(false);

  const setStepIndex = (index) => {
    dispatch(changeEntryStepStatus(index));
  };

  const uploadedFileCB = (file) => {
    setUploadedImageUrl(URL.createObjectURL(file));
    dispatch(removeCanvas());
    setStepIndex(1);
  };

  const modifiedImageCB = (file) => {
    setFileToUpload(file);
    setFinalImageUrl(URL.createObjectURL(file));
  };

  const [backDisabled, setBackDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    setBackDisabled(currentStepIndex < 1);
    setNextDisabled(currentStepIndex === 3);
  }, [currentStepIndex]);

  useEffect(() => {
    if (settings) {
      const {defaulttemplateid, smstemplate, facebooktemplate} = settings;

      if (defaulttemplateid && smstemplate) {
        setCanAddEntry(true);
        dispatch(getTemplateById({id: settings.defaulttemplateid})).then(
          (res) => {
            if (res.payload.error) {
            } else {
              const template = res.payload.data[0];
              setTemplate(template);
            }
          }
        );
      } else {
        setCanAddEntry(false);
      }
    }
  }, [settings]);

  useEffect(() => {
    if (submitEntryStatus === "success") {
      if (mergeTags) {
        const mergedTemplate = mergeTagsToHtml(
          template.contenthtml ?? "",
          mergeTags
        );
        setEmailcontent(mergedTemplate);
      }

      if (settings) {
        const {smstemplate, facebooktemplate} = settings;
        if (smstemplate) {
          setSmscontent(mergeTagsToHtml(smstemplate, mergeTags));
        }

        if (postOnFacebook && facebooktemplate) {
          SetFacebookContent(mergeTagsToHtml(facebooktemplate, mergeTags));
        }
      }
    }
  }, [submitEntryStatus]);

  useEffect(() => {
    if (emailcontent) {
      const data = {
        user,
        companyName: company.name,
        fromEmail: "noreply@engagr.com.au",
        toEmail: formValues.email,
        subject: template.subject,
        subTitle: template.subtitle,
        html: emailcontent,
      };
      const dispatchSendEmail = dispatch(sendEmail({data}));

      dispatchSendEmail.then((res) => {
        if (res.payload.error) {
          setSendEmailStatus("error");
          setNotifications((state) => ({
            ...state,
            ...{emailsent: false, emailcontent},
          }));
        } else {
          setSendEmailStatus("success");
          setNotifications((state) => ({
            ...state,
            ...{emailsent: true, emailcontent},
          }));
        }
      });
    }
  }, [emailcontent]);

  useEffect(() => {
    if (smscontent) {
      const dispatchSMS = dispatch(
        sendSMS({
          data: {
            sendTo: formValues.contactnumber,
            content: smscontent,
          },
        })
      );

      dispatchSMS.then((res) => {
        if (res.payload.error) {
          setSendSmsStatus("error");
          setNotifications((state) => ({
            ...state,
            ...{smssent: false, smscontent},
          }));
        } else {
          setSendSmsStatus("success");
          setNotifications((state) => ({
            ...state,
            ...{smssent: true, smscontent},
          }));
        }
      });
    }
  }, [smscontent]);

//   const postToIG = async () => {
//    await window.FB.api(
//         '/17841459106110015/media',
//         'POST',
//         {
//             access_token: 'EAACfhBMgoywBAB7sPZArv3FdJ1gVmaJRt615esJd3OVRBTKzH3ZCZBgEqAjoKux4UKaMxvLtwDsPi5R6tzBEIRGXBts5KCTlfsAACFMUs8g90ZCG5zlg0IObHofmaaSJ8pRoN09NvUlqa3xcg9v5t4jJRJcatkya9fKNteLguoomn2DSRx9ZAJZBSuJqvf7qQpgEFpBPdAoZBCrePFXuh7RCHVL25vIGbwZD',
//             image_url: formValues.photo,
//             caption: facebookContent,
//         },
//         function(response) {
//             console.log(response);
//             if (!response || response.error) {
//                 alert('Error occurred while posting to Instagram.');
//             } else {
//                 alert('Image posted successfully!');
//             }
//         }
//     );

//     console.log()
// }


  useEffect(() => {
    if (facebookContent && settings?.pageaccesstoken?.length) {
      // post to facebook
      const dispatchPostToFBPage = dispatch(
        postToFBPage({
          data: {
            imageUrl: formValues.photo,
            content: facebookContent,
            pageId: settings.facebookpageid,
            hourstodelay: formValues.hourstodelay,
            accessToken: settings.pageaccesstoken,
          },
        })
      );

   
  
        dispatchPostToFBPage.then((res) => {
          console.log(res, "res in post to fb callback");
          if (res.payload.hasOwnProperty("error")) {
            setPostOnFBStatus("error");
            setNotifications((state) => ({
              ...state,
              ...{fbposted: false, fbpostcontent: facebookContent},
            }));
          } else {
            setPostOnFBStatus("success");
            setNotifications((state) => ({
              ...state,
              ...{fbposted: true, fbpostcontent: facebookContent},
            }));
          }
        });


    }

  }, [facebookContent]);

  useEffect(() => {
    if (sendSmsStatus !== "pending" && sendEmailStatus !== "pending") {
      if (postOnFacebook) {
        if (postOnFBStatus !== "pending") {
          setEntryProcessed(true);
        }
      } else {
        setEntryProcessed(true);
      }
    }
  }, [sendSmsStatus, sendEmailStatus, postOnFacebook, postOnFBStatus]);

  useEffect(() => {
    if (entryProcessed) {
      const data = {
        entryid: entry?.id,
        companyid: company?.id,
        userId: user?.id,
        ...notifications,
      };

      dispatch(addNotification({data})).then((res) => {});
    }
  }, [entryProcessed]);

  const uploadTheFile = async (values) => {
    if (fileToUpload) {
      const uploadedFile = await uploadToSB({
        companyName: company.name,
        entity: {
          id: user.id,
          email: values.email,
        },
        bucket: "engagr",
        fileToUpload,
        dispatch,
      });
      if (uploadedFile.data) {
        return uploadedFile.data;
      }

      if (uploadedFile.error) {
        dispatch(
          setAlert({
            show: true,
            type: "warning",
            content:
              "Failed to upload the image please try again after sometime",
          })
        );
        return false;
      }
    }
  };

  const processMergeTags = (values) => {
    if (template.mergetags) {
      const mergeTagsToProcess = JSON.parse(template.mergetags);

      const {
        company,
        user,
        postOnFacebook,
        postOnInstagram,
        leadSource,
        ...customer
      } = values;

      setMergeTags({company: mergeTagsToProcess.company, customer});
    }
  };

  const onRGFChangeCB = (value) => {
    setShowScheduler(value.id === "schedule-post");
  };
  const handleSubmit = async (values, actions) => {
    if (currentStepIndex !== 3) {
      currentStepIndex === 2 && setFormValues(values);
      setStepIndex(currentStepIndex + 1);
    } else {
      actions.setSubmitting(true);
      setShowSubmitStatus(true);
      values.userid = user.id;
      values.companyid = company.id;

      const uploadUrl = await uploadTheFile(values);
      if (uploadUrl) values.photo = uploadUrl;
      await processMergeTags(values);

      // dispatch(setLoading(true))
      await dispatch(addEntry({data: values})).then((res) => {
        if (res.payload.error) {
          setSubmitEntryStatus("error");
        } else {
          setSubmitEntryStatus("success");
        }
      });
    }
  };

  const renderCurrentStepIndexContent = () => {
    switch (currentStepIndex) {
      case 0:
        return (
          <div className="col-span-6">
            <UploadFile
              name="welcomeImage"
              label="PNG, JPG, GIF up to 1MB"
              uploadedFileCB={uploadedFileCB}
            />
          </div>
        );
      case 1:
        return (
          <Canvas
            imageUrl={uploadedImageUrl}
            modifiedImageCB={modifiedImageCB}
          />
        );
      case 2:
        return (
          <>
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                      Product Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      Information of the product that has been sold
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow sm:rounded-md sm:overflow-hidden pt-4">
                    <div className="px-4 pb-5 dark:bg-gray-800 space-y-6 ">
                      <div className="grid ">
                        <div className="col-span-6 sm:col-span-3">
                          <InputField name="product" label="Product Name" />
                        </div>
                        <div className="pt-4 col-span-3 sm:col-span-1">
                          <RadioGroupField
                            name="condition"
                            label="Condition"
                            options={conditions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-2 border-gray-200 dark:border-gray-600" />
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                      Customer Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      Customer personal information including email, contact
                      number and address.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow sm:rounded-md sm:overflow-hidden pt-4">
                    <div className="px-4 pb-5 dark:bg-gray-800 space-y-6 ">
                      <PersonalDetailsForm showPhoneField={true} />

                      <div className="grid grid-cols-6 gap-6">
                        <AddressForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-2 border-gray-200 dark:border-gray-600" />
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                      Post to Facebook
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      Choose when you want to post to Facebook page.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 dark:bg-gray-800 space-y-6 sm:p-6">
                      {settings?.pageaccesstoken ? (
                        <fieldset>
                          <div
                            className="text-base font-medium text-gray-900 dark:text-gray-200"
                            aria-hidden="true"
                          >
                            Facebook
                          </div>
                          <div className="mt-4">
                            <SwitchField
                              name="usedefaultpostsettings"
                              label="Use default facebook post options"
                            />
                          </div>

                          {postOnFacebook && (
                            <div className="mt-4 space-y-4">
                              <div className="grid grid-cols-1 gap-2">
                                <div className="col-span-2 sm:col-span-1">
                                  <RadioGroupField
                                    name="postoptions"
                                    label="Facebook Post Options"
                                    options={fbPostOptions}
                                    onChangeCB={onRGFChangeCB}
                                  />
                                </div>
                                {showScheduler && (
                                  <div className="col-span-2 sm:col-span-1 pt-5">
                                    <SelectField
                                      name="hourstodelay"
                                      className="w-48"
                                      label="Hours to delay posting"
                                      data={hoursToDelayOptions}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </fieldset>
                      ) : (
                        <fieldset>
                          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationIcon
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                  Need to authorise Engagr app to post to
                                  facebook. Can be done in the settings {">"}{" "}
                                  facebook page. Otherwise submission will only
                                  send Email and SMS based on settings
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-2 border-gray-200 dark:border-gray-600" />
              </div>
            </div>
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                      Other Information
                    </h3>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow sm:rounded-md sm:overflow-hidden pt-4">
                    <div className="px-4 pb-5 dark:bg-gray-800 space-y-6 ">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <SelectField
                            name="leadsource"
                            label="Lead"
                            data={leadOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          formValues && (
            <EntryPreview
              formValues={formValues}
              image={finalImageUrl}
              showSubmitStatus={showSubmitStatus}
              submitEntryStatus={submitEntryStatus}
              smsStatus={sendSmsStatus}
              emailStatus={sendEmailStatus}
              fbStatus={postOnFBStatus}
            />
          )
        );
      default:
    }
  };

  return (
    <>
      {!canAddEntry ? (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Please make sure you have selected an <b>email template</b> and
                saved <b>sms template</b>.{" "}
                <span>
                  You can add templates in <u>Settings {">"} Presets</u>
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Stepper />
          <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({values, errors, touched, isSubmitting, setFieldValue}) => {
              useEffect(() => {
                if (conditions) {
                  setFieldValue("condition", conditions[0]);
                }
              }, [conditions]);

              useEffect(() => {
                if (settings.postoptions) {
                  const postOptionObject = JSON.parse(settings.postoptions);
                  console.log("postOptionObject", postOptionObject);
                  setFieldValue(
                    "postoptions",
                    fbPostOptions[findIndex(fbPostOptions, postOptionObject)]
                  );
                  setShowScheduler(postOptionObject.id === "schedule-post");
                  setFieldValue("usedefaultpostsettings", true);
                  setFieldValue("postonfacebook", true);
                  setPostOnFacebook(true);
                } else {
                  setFieldValue(fbPostOptions[0]);
                }

                if (settings.hourstodelay) {
                  setFieldValue("hourstodelay", settings.hourstodelay);
                }
              }, [settings, setFieldValue]);

              return (
                <Form id="add-entry">
                  <div className="overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5  sm:p-6">
                      {renderCurrentStepIndexContent()}
                    </div>
                    <div className="grid grid-cols-6 gap-4 justify-items-stretch px-4 py-5  sm:p-6">
                      <div className="col-start-1 col-end-3">
                        <button
                          onClick={() =>
                            currentStepIndex === 2 && !uploadedImageUrl
                              ? setStepIndex(0)
                              : setStepIndex(currentStepIndex - 1)
                          }
                          disabled={backDisabled}
                          type="button"
                          className={classNames(
                            currentStepIndex < 2 ? "disabled:opacity-25" : "",
                            "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          Back
                        </button>
                      </div>
                      {currentStepIndex < 2 && (
                        <div className="col-end-7 col-span-2 justify-self-end">
                          <button
                            onClick={() =>
                              !uploadedImageUrl
                                ? setStepIndex(2)
                                : setStepIndex(currentStepIndex + 1)
                            }
                            className={classNames(
                              nextDisabled ? "disabled:opacity-25" : "",
                              "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            )}
                            type="button"
                          >
                            Next
                          </button>
                        </div>
                      )}
                      {currentStepIndex === 2 && (
                        <div className="col-end-7 col-span-2 justify-self-end">
                          <button
                            className={classNames(
                              nextDisabled ? "disabled:opacity-25" : "",
                              "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            )}
                            type="submit"
                          >
                            Next
                          </button>
                        </div>
                      )}
                      {currentStepIndex === 3 && (
                        <div className="col-end-7 col-span-2 justify-self-end">
                          <button
                            className={classNames(
                              isSubmitting ? "disabled:opacity-25" : "",
                              "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            )}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export default AddEntry;
