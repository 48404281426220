import SubmitStatus from "./SubmitStatus";

const EntryPreview = (props) => {
    const {
        formValues,
        image,
        showSubmitStatus,
        submitEntryStatus,
        smsStatus,
        emailStatus,
        fbStatus
    } = props
    return (
        <div className="max-w-2xl mx-auto mt-10 bg-white overflow-hidden shadow rounded-lg">
            <div className="">
                <div className="px-4 py-5 sm:p-6">
                    <img src={image} className="w-full overflow-hidden shadow rounded-xl"/>
                </div>
            </div>
            <dl className="divide-y sm:divide-gray-200">
                <div className="py-4 py-5 grid grid-cols-3 gap-4 px-6">
                    <dt className="text-sm font-medium text-gray-500">Vehicle</dt>
                    <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2">{formValues.product}</dd>
                </div>
                <div className="py-4 py-5 grid grid-cols-3 gap-4 px-6">
                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                    <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2">{formValues.firstname} {formValues.lastname}</dd>
                </div>
                <div className="py-4 py-5 grid grid-cols-3 gap-4 px-6">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2">{formValues.email}</dd>
                </div>
                <div className="py-4 py-5 grid grid-cols-3 gap-4 px-6">
                    <dt className="text-sm font-medium text-gray-500">Contact Number</dt>
                    <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2">{formValues.contactnumber}</dd>
                </div>
                <div className="py-4 py-5 grid grid-cols-3 gap-4 px-6">
                    <dt className="text-sm font-medium text-gray-500">Address</dt>
                    <dd className="text-sm text-gray-900 mt-0 col-span-2">
                        {formValues.addresslineone} {formValues.addresslinetwo &&  <>  {formValues.addresslinetwo}</>} {formValues.city} {formValues.state} - {formValues.postcode}
                    </dd>
                </div>
                <div className="py-4 py-5 grid grid-cols-3 gap-4 px-6">
                    <dt className="text-sm font-medium text-gray-500">Lead</dt>
                    <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2">{formValues.leadsource}</dd>
                </div>
            </dl>

            {showSubmitStatus && <div className={'p-4 bg-white'}>
                <SubmitStatus key={'entry'} status={submitEntryStatus}
                              processingText={'Submitting entry details'}
                              processedText={'Entry details has been submitted'}
                              errorText={'Failed to submit entry'}
                />

                <SubmitStatus key={'sms'} status={smsStatus}
                              processingText={'Sending SMS'}
                              processedText={'SMS has been sent'}
                              errorText={'Failed to send sms'}

                />

                <SubmitStatus key={"email"}
                              status={emailStatus}
                              processingText={'Sending email'}
                              processedText={'Email has ben sent'}
                              errorText={'Failed to send email'}
                />

                <SubmitStatus key={"fb"}
                              status={fbStatus}
                              processingText={'Posting to facebook page'}
                              processedText={'Finished posting to facebook page'}
                              errorText={'Failed to post to facebook page'}
                />
            </div>}
        </div>
    )
}

export default EntryPreview;
