import {useEffect, useState} from "react";
import {CheckCircleIcon, ExclamationIcon, XCircleIcon} from "@heroicons/react/solid";

const SubmitStatus = (props) => {
    const {
        status,
        processedText,
        processingText,
        errorText,
    } = props

    const [displayText, setDisplayText] = useState('')
    useEffect(() => {

        switch (status) {
            case "pending":
                setDisplayText(processingText)
                break;
            case "success":
                setDisplayText(processedText)
                break
            case "error":
                setDisplayText(errorText)
        }
    }, [status])
    return (
        <div role="status">
            {status === 'pending' && <>
                <div className="rounded-md bg-yellow-50 p-4 m-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>

                            <div className="pl-2 text-sm text-yellow-700">
                                <p> {displayText} </p>
                            </div>

                    </div>
                </div>
            </>}
            {status === 'success' && <>
                <div className="rounded-md bg-green-50 m-4 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>

                            <div className="pl-2 text-sm text-green-700">
                                <p> {displayText} </p>
                            </div>

                    </div>
                </div>
            </>}
            {status === 'error' && <>
                <div className="rounded-md bg-red-50 p-4 m-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>

                            <div className="pl-2 text-sm text-red-700">
                                <p> {displayText} </p>
                            </div>

                    </div>
                </div>
            </>}
        </div>
    )
}

export default SubmitStatus