import React from 'react';
import {at} from 'lodash';
import {useField} from 'formik';

const InputField = (props) => {

    const {name, label,className, ...rest} = props;
    const [field, meta] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error;

    function renderHelperText() {
        return <p className="text-rose-500">{error}</p>;
    }

    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                {label}
            </label>
            <input type={name=== 'password' ? 'password' : "text"} {...field} {...rest}
                   className={`mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm
            border-gray-300 rounded-sm border-4 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className ? className : ''}`}/>
            {isError ? renderHelperText() : ""}
        </div>
    );
}

export default InputField;
