import React, {useEffect, useState} from "react";
import {RadioGroup} from '@headlessui/react'
import {CheckCircleIcon} from '@heroicons/react/solid'
import {at, findIndex} from 'lodash';
import {useField} from 'formik';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const RadioGroupField = (props) => {

    const {name, label, options, ...rest} = props;
    const [field, meta, helpers] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error;
    const [rgValue, setRgValue] = useState(options[0])
    const handleOnChange = (value) => {
        setRgValue(value)
        helpers.setValue(value)
        if(props.onChangeCB){
            props.onChangeCB(value)
        }

    }
    function renderHelperText() {
        return <p className="text-rose-500">{error}</p>;
    }

    useEffect(()=>{
        if( field.value !== options[0]){
            setRgValue(options[findIndex(options, field.value)])
        }
    },[field.value])
    return (
        <>
            <RadioGroup name value={rgValue} onChange={handleOnChange}>
                <RadioGroup.Label className="text-sm font-medium text-gray-900 dark:text-gray-400">{label}</RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {options.map((option) => (
                        <RadioGroup.Option
                            key={option.id}
                            value={option}
                            className={({checked, active}) => {
                                return classNames(
                                    checked ? 'border-transparent' : 'border-gray-300',
                                    active ? 'border-blue-500 ring-4 ring-blue-500' : '',
                                    'relative bg-white dark:bg-gray-800 dark:border-gray-700 border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                )
                            }

                            }
                        >
                            {({checked, active}) => (
                                <>
                                <span className="flex-1 flex">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900 dark:text-gray-200">
                                      {option.label}
                                    </RadioGroup.Label>
                                  </span>
                                </span>
                                    <CheckCircleIcon
                                        className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-blue-600')}
                                        aria-hidden="true"
                                    />
                                    <span
                                        className={classNames(
                                            active ? 'border' : 'border-4',
                                            checked ? 'border-blue-500' : 'border-transparent',
                                            'absolute -inset-px rounded-lg pointer-events-none'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            {isError ? renderHelperText() : ""}
        </>

    )
}

export default RadioGroupField
