import React from "react";
import {Disclosure} from '@headlessui/react'
import { NavLink, useLocation } from "react-router-dom";
import {useAuth} from '../../hooks/useAuth'
import {ChevronDownIcon} from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MainNav({navClass, navigation, setSidebarOpen}) {

    const auth = useAuth();

    const location = useLocation();

    return (
        <nav className={navClass}>

            {navigation.map((item) =>
                !item.children ? (
                    <div key={item.name}>
                        {auth.userRole >= parseInt(item.roleLevel) && <NavLink
                            to={item.href}
                            className={classNames(
                                location.pathname === item.href
                                    ? 'text-white bg-gray-100 dark:bg-gray-700'
                                    : 'hover:bg-gray-100 dark:hover:text-gray-200 dark:hover:bg-gray-700',
                                'text-slate-700 dark:text-gray-100 mb-1 group leading-tight tracking-tight w-full flex items-center pl-2 py-2 rounded-r-md rounded-b-md'
                            )}
                        >
                        <div className="flex items-center rounded shadow-sm block hover:shadow-xl text-gray-600 dark:text-white p-2" style={{backgroundColor:item.iconColor}}>
                            <item.icon
                                className={classNames(
                                    location.pathname === item.href ? 'text-gray-600' : 'text-gray-600',
                                    'flex-shrink-0 h-6 w-6 dark:text-gray-400'
                                )}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="mt-1 ml-3">
                            <div className="text-1xl">{item.name}</div>
                            <div className="text-xs dark:text-gray-400">{item.sub}</div>
                        </div>
                        </NavLink>}
                    </div>
                ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                        {({open}) => (
                            auth.userRole >= parseInt(item.roleLevel) && <>
                                <Disclosure.Button
                                    className={classNames(
                                        location.pathname === item.href
                                            ? 'bg-gray-900 text-white bg-gray-100'
                                            : 'tracking-tight hover:text-slate-800 hover:bg-gray-100 dark:hover:bg-gray-700',
                                        'text-slate-700 dark:text-gray-100 font-light leading-tight mb-1 text-1xl group w-full flex items-center pl-2 pr-1 py-2 text-left font-bold rounded-r-md rounded-b-md focus:outline-none'
                                    )}
                                >
                                <div className="flex items-center rounded shadow-sm block hover:shadow-xl text-gray-600 p-2" style={{backgroundColor:item.iconColor}}>
                                    <item.icon
                                        className={classNames(
                                            location.pathname === item.href ? 'text-gray-600' : 'text-gray-600 group-hover:text-gray-600 dark:group-hover:text-gray-200',
                                            'flex-shrink-0 h-6 w-6 dark:text-gray-400'
                                        )}
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="mt-1 ml-3">
                                    <div className="text-1xl">{item.name}</div>
                                    <div className="text-xs dark:text-gray-400">{item.sub}</div>
                                </div>
                                <ChevronDownIcon
                                    className="hidden flex-shrink-0 ml-auto h-5 w-5 mr-2 text-gray-600 dark:text-gray-400 lg:block"
                                    aria-hidden="true"
                                />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1 pb-3 overflow-hidden">
                                    {item.children.map((subItem) => (
                                        auth.userRole >= parseInt(subItem.roleLevel) && (
                                            <NavLink
                                                key={subItem.name}
                                                to={subItem.href}
                                                className="group w-full flex items-center ml-10 py-2 text-1xl font-light leading-tight tracking-tight text-gray-900 dark:text-gray-300"
                                            >
                                                <span
                                                className={classNames(
                                                    location.pathname === subItem.href
                                                        ? 'text-blue-500 dark:text-blue-500'
                                                        : 'text-gray-300',
                                                        'mr-2 ml-1'
                                                )}
                                                >→ {subItem.name}</span>
                                            </NavLink>)
                                    ))}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                )
            )}
        </nav>
    )
}
