const states = [
    {value: "", label: "Select state"},
    {value: "New South Wales", label: "New South Wales"},
    {value: "Victoria", label: "Victoria"},
    {value: "Queensland", label: "Queensland"},
    {value: "South Australia", label: "South Australia"},
    {value: "Western Australia", label: "Western Australia"},
    {value: "Tasmania", label: "Tasmania"},
    {value: "Northern Territory", label: "Northern Territory"},
    {value: "Australian Capital Territory", label: "Australian Capital Territory"}
];

export default states;