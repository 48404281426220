// TODO Find better way to validate dropzone field
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {useField, useFormikContext} from "formik";
import {at} from "lodash";

const UploadFile = (props) => {

    const {name, label, allowedFiles,uploadedFileCB} = props;
    const [ meta] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error;

    const {setFieldValue} = useFormikContext();

    function renderHelperText() {
        return <p className="text-rose-500">{error}</p>;
    }

    const onDrop = useCallback((acceptedFiles) => {

        acceptedFiles.forEach(async (file) => {
            uploadedFileCB(file)
        })

    }, [setFieldValue, name])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        multiple: false,
        accept: allowedFiles
    })

    return (
        <>



            <div {...getRootProps()} className="flex dropzone justify-center px-6 pt-5 pb-6 border-2 bg-gray-300 border-gray-300 border-dashed rounded-md" style={{ minHeight: 'calc(100vh - 520px)' }}>
                <div  className="space-y-1 text-center grid place-content-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                            <span>Upload a file(s)</span>
                            <input {...getInputProps({name})}  />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p  className="text-xs text-gray-500">{label}</p>
                    {isError ? renderHelperText() : ""}
                </div>
            </div>

        </>
    )
}

export default UploadFile;
