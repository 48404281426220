// import {useLocation, useNavigate, useParams} from "react-router-dom";
import React from 'react';
import { useState} from "react";
import {
    AdjustmentsIcon,
    HandIcon,
    NewspaperIcon,
    CalendarIcon,
    CollectionIcon
} from '@heroicons/react/outline'

import RecentPosts from "../../views/Facebook/RecentPosts";
import Presets from "../../views/Settings/Presets";
import FacebookSettings from "../../views/Settings/FacebookSettings";

const Facebook = () => {

  const [activeSection, setActiveSection] = useState('recent-posts');

  const tabs = [
    { id: 1, icon: NewspaperIcon, title: 'Recent posts', sub: 'Browse your latest Facebook activity and shared content in one place.', link: 'recent-posts', component: FacebookSettings},
    { id: 2, icon: HandIcon, title: 'Draft posts', sub: 'Work in progress: unfinished blog posts waiting for review and publishing.', link: 'draft-posts', component: RecentPosts},
    { id: 3, icon: CalendarIcon, title: 'Scheduled posts', sub: 'Manage upcoming Facebook content with Scheduled Posts.', link: 'scheduled-posts', component: RecentPosts},
    { id: 4, icon: AdjustmentsIcon, title: 'Presets', sub: 'Manage your Facebook, SMS, Banner presets in one convenient location.', link: 'presets', component: Presets},
    { id: 5, icon: CollectionIcon, title: 'Connect Facebook', sub: 'Link Facebook for easy access and social sharing on our platform.', link: 'connect-facebook', component: FacebookSettings}
  ]

    return (
   <>


   <main className="min-w-0 flex-1 grid lg:flex dark:bg-gray-900 overflow-hidden">

   {tabs.map((card) => (
      activeSection === card.link && (
      <section
        aria-labelledby="message-heading"
        className="flex lg:min-w-0 max-w-4xl flex-1 flex-col overflow-hidden order-last"
        key={card.link} // Add a unique key prop
        >
        <ul className="space-y-2 py-4 sm:space-y-4 px-0 lg:px-4">
          {/* <li className="DELETE WHEN FINISHED">{card.title}</li> */}
           <card.component />
        </ul>
      </section>
      )
    ))}




    <aside className="border-gray-200 sm:border-l-2 sm:border-r-2 dark:border-gray-700 order-first block lg:flex-shrink-0 dark:bg-gray-900 bg-white">
      <div className="relative flex w-100 lg:w-96 flex-col border-r border-l border-gray-200 dark:border-gray-600">
             <div className="p-10 text-4xl sm:text-4xl text-slate-800 dark:text-gray-100 font-extrabold leading-tight tracking-tight">Facebook</div>
             <nav aria-label="Message list" className="min-h-0 flex-1">
              <ul className="divide-y divide-gray-200 dark:divide-gray-500 setting-nav">

              {tabs.map((card) => (

                   <li key={card.id} className={`relative py-5 px-6 hover:bg-gray-50 dark:hover:bg-gray-700
                      ${activeSection === card.link ? 'bg-gray-50 dark:bg-gray-700 dark:text-gray-200' : 'dark:text-gray-400'}`}
                      onClick={() => setActiveSection(activeSection === card.link ? null : card.link)}>
                      <div className="flex px-3 py-1 cursor-pointer bg-primary-50 dark:bg-hover">
                     <div role="img" className="text-primary" aria-hidden="true">
                      <card.icon className="w-10 h-10"/>
                     </div>
                     <div className="ml-3">
                     <div className="font-bold leading-6 text-slate-700 dark:text-gray-300"> {card.title} </div>
                     <div className="mt-0.5 text-xs text-slate-700 dark:text-gray-400"> {card.sub} </div>
                     </div>
                   </div>
                   </li>

                ))}

               </ul>
             </nav>
           </div>
         </aside>
       </main>


   </>
    )
}
export default Facebook;
