import {setAlert, setLoading} from "../store/features/app";
import {slugify} from "./index";
import sha256 from 'crypto-js/sha256';
import {supabase, sbEbuilder} from "./sbClient";
import blankImage from '../assets/images/blank.png'
const ENGAGR_BUCKET = process.env.REACT_APP_SUPABASE_BUCKET
const EBUILDER_BUCKET = process.env.REACT_APP_EBUILDER_SUPABASE_BUCKET
const HASH_SECRET = process.env.REACT_APP_HASH_SECRET
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL

export const uploadToSB = async (options) => {
    const {dispatch, fileToUpload, companyName, entity, bucket = "engagr"} = options

    const sbBucket = bucket === "engagr" ? ENGAGR_BUCKET : EBUILDER_BUCKET

    if (fileToUpload) {
        try {
            let fileKey = "";
            let hashedFileName = ""
            if (entity) {
                hashedFileName = sha256(
                    `${Date.now().toString()}${entity.id}${entity.email}${fileToUpload.name}`,
                    HASH_SECRET
                )

                fileKey = `${slugify(companyName)}/${hashedFileName}.${fileToUpload.name.split('.').pop()}`
            } else {
                hashedFileName = sha256(
                    `${Date.now().toString()}${fileToUpload.name}`,
                    HASH_SECRET
                )

                fileKey = `${slugify(companyName)}/${hashedFileName}.${fileToUpload.name.split('.').pop()}`
            }

            const {data, error} = await supabase.storage
                .from(ENGAGR_BUCKET)
                .upload(fileKey, fileToUpload)

            const fileUrl = `${SUPABASE_URL}/storage/v1/object/public/${ENGAGR_BUCKET}/${fileKey}`

            return {data: fileUrl, error: null}
        } catch (error) {

            dispatch(setAlert({
                    show: true,
                    type: "error",
                    content: "There has been an issue uploading the image"
                }
            ));
            dispatch(setLoading(false))
            return {error, data: null}

        }
    }

}


export const createSBFolder = async (options) => {

    const {folderKey, app = "engagr"} = options

    const storage = app === "engagr" ? supabase.storage : sbEbuilder.storage

    const sbBucketName = app === "engagr" ? ENGAGR_BUCKET : EBUILDER_BUCKET;

    const path = app === 'ebuilder' ? `engagr/${slugify(folderKey)}` : slugify(folderKey)

    const {data: list, error:listError} = await storage
        .from(sbBucketName)
        .list(`${path}`)

    if(list.length <= 0){
        const blob = await (await fetch(blankImage)).blob();
        const file = new File([blob], 'blank.png', {type: "image/png", lastModified: new Date()});
        const {data, error} = await storage
            .from(sbBucketName)
            .upload(`${path}/blank.png`,file)
    }

}


