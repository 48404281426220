import {InputField} from "../UI/FormControls";
import React from "react";

const PersonalDetailsForm = ({showPhoneField, showTitleField, showPasswordField, hideEmailField, disableEmail}) => {

    return (
        <>
        <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <InputField name="firstname" label="First Name"/>
            </div>
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <InputField name="lastname" label="Last Name"/>
            </div>
            {showTitleField && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <InputField name="title" label="Job Title"/>
                </div>
            )}
            {showPhoneField && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <InputField name="contactnumber" label="Contact Number"/>
                </div>
            )}
            {!hideEmailField &&
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <InputField name="email" label="Email Address" disabled={disableEmail} className={disableEmail ? 'bg-gray-300': ''}/>
            </div>}
            {showPasswordField && (
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <InputField name="password" label="Password"/>
                </div>
            )}


            </div>
        </>
    )
}

export default PersonalDetailsForm;
