function timeAgo(value) {
    const timestamp = Date.parse(value) / 1000;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const diff = currentTimestamp - timestamp;
  
    if (diff < 60) {
      return "just now";
    } else if (diff < 3600) {
      const minutes = Math.floor(diff / 60);
      const timeString = new Date(timestamp * 1000).toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true });
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago at ${timeString}`;
    } else if (diff < 86400) {
      const hours = Math.floor(diff / 3600);
      const timeString = new Date(timestamp * 1000).toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true });
      return `${hours} hour${hours > 1 ? "s" : ""} ago at ${timeString}`;
    } else if (diff < 172800) {
      const timeString = new Date(timestamp * 1000).toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true });
      return `yesterday at ${timeString}`;
    } else {
      const dateString = new Date(timestamp * 1000).toLocaleDateString('en-AU', { month: 'numeric', day: 'numeric', year: 'numeric' });
      const timeString = new Date(timestamp * 1000).toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true });
      return `${dateString} at ${timeString}`;
    }
  }

export {timeAgo}