import { createClient } from '@supabase/supabase-js'

export const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY
)

export const sbEbuilder = createClient(
    process.env.REACT_APP_EBUILDER_SUPABASE_URL,
    process.env.REACT_APP_EBUILDER_SUPABASE_ANON_KEY
)
