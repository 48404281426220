/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {Dialog, Transition} from '@headlessui/react'

import {InputField} from "../FormControls";
import {Form, Formik} from "formik";

import * as Yup from "yup";

import {useDispatch, useSelector} from "react-redux";

import mergeTags from '../../../data/mergeTags'
import {addTemplate, getTemplates} from "../../../store/features/template/thunks";
import {setAlert} from "../../../store/features/app";

const formInitialValues = {
    templatename: ''
}

const validationSchema = Yup.object().shape({
    templatename: Yup.string()
        .required(`Template name is required`),
})
const CreateTemplate = ({show, onStateChange, duplicateTemplateData}) => {
    const [open, setOpen] = useState(show)
    const [disableNext, setDisableNext] = useState(false)

    const company = useSelector(state => state.user?.company)
    const userId = useSelector(state => state.user?.id)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(show)
    }, [show])

    const hideModal = () => {
        setOpen(false)
        onStateChange(false)
    }
    const loadDataToMergeTags = async () => {
        mergeTags.company.name = company.name
        mergeTags.company.logo = company.logo
        mergeTags.company.website = company.website
        mergeTags.company.addresslineone = company.addresslineone
        mergeTags.company.addresslinetwo = company.addresslinetwo
        mergeTags.company.city = company.city
        mergeTags.company.state = company.state
        mergeTags.company.postcode = company.postcode
        mergeTags.company.contactnumber = company.contactnumber
    }




    const handleSubmit = async (values, actions) => {
        actions.setSubmitting(true);
        console.log(actions)
        setDisableNext(true)
        await loadDataToMergeTags(mergeTags);
        let payload = {};
        if (duplicateTemplateData) {
            const {
                createdon,
                createdby,
                updatedon,
                updatedby,
                active,
                name,
                id,
                ...duplicatePayload
            } = duplicateTemplateData

            payload = {
                name: values.templatename,
                product: "engagr",
                companyid: company?.id,
                userId,
                ...duplicatePayload
            }
        } else {
            payload = {
                name: values.templatename,
                product: "engagr",
                companyid: company?.id,
                userId,
                mergetags: JSON.stringify(mergeTags)
            }
        }

        dispatch(addTemplate({data: payload})).then(res => {

            if (res.payload.error) {
                dispatch(setAlert({
                    show: true,
                    type: "error",
                    content: res.payload.error
                }));
            } else {
                if (duplicateTemplateData) {
                    dispatch(getTemplates)
                } else {

                  const builderUrl  = `${process.env.REACT_APP_EBUILDER_URL}/editor?id=${res.payload.data[0].id}&product=engagr&companyId=${company.id}&userId=${userId}`;
                    window.open(builderUrl, '_blank');
                }
                hideModal()
                dispatch(setAlert({
                    show: true,
                    type: "success",
                    content: "Successfully created template"
                }));
            }


        });

        actions.setTouched({});
        actions.setSubmitting(false);


    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={hideModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {duplicateTemplateData ? "Duplicate " : "Create "} template
                                    </Dialog.Title>
                                    <Formik
                                        initialValues={formInitialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({errors, touched, isSubmitting, setFieldValue}) => {

                                            useEffect(() => {

                                                if (duplicateTemplateData) {
                                                    setFieldValue('templatename', `${duplicateTemplateData.name} (copy)`)
                                                }
                                            }, [duplicateTemplateData])
                                            return (
                                                <Form id="add-template">
                                                    <div className="mt-3 sm:mt-5">
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <div className="col-span-2 sm:col-span-1">
                                                                <InputField name="templatename" label="Template name"/>
                                                            </div>
                                                            <div className="mt-5 sm:mt-6">
                                                                <div className="flex justify-end">
                                                                    <button
                                                                        type="submit"
                                                                        disabled={disableNext}
                                                                        className={(disableNext ? "cursor-not-allowed focus:outline-none disabled:opacity-25 " : "") + "inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"}
                                                                    >
                                                                        Next
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="inline-flex justify-center  rounded-md border border-transparent shadow-sm ml-2 px-4 py-2 bg-blue-800 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                                        onClick={hideModal}
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )
                                        }}

                                    </Formik>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default CreateTemplate;
