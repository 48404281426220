import React, { useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-apexcharts";
import {getCurrentAndLastMonthTopEntries} from "../../store/features/dashboard/thunks";
// import PillChart from "./pillChart";
// import PillChartB from "./pillChart_B";
import Spinner from '../../components/UI/Spinner.js'

const DashboardHeader = () => {
    const dispatch = useDispatch();
    const companyId = "28d5a1f1-39ff-4fa7-b33a-14e4e440143d";

    useEffect( () => {
        if(companyId){
            dispatch(getCurrentAndLastMonthTopEntries({companyId}));
        }
    }, [dispatch, companyId])

    const topEntries = useSelector(state => state.dashboard?.topEntries);
    const chartData = topEntries?.length ? createEqualEntries(topEntries) : [];

    function createEqualEntries(){
        let currentMonthEntries = Object.values(topEntries[0].entries)
        let prevMonthEntries = Object.values(topEntries[1].entries)
 
    if(currentMonthEntries.length > prevMonthEntries.length){
            let diff = currentMonthEntries.length - prevMonthEntries.length
            for(let i=0; i<diff; i++){
                prevMonthEntries.push(0)
            }
        }else if(currentMonthEntries.length < prevMonthEntries.length){
            let diff = prevMonthEntries.length - currentMonthEntries.length
            for(let i=0; i<diff; i++){
                currentMonthEntries.push(0)
            }
        }

        return topEntries.map((entry, index) => {
            return {
                name: entry.month,
                data: index === 0 ? currentMonthEntries : prevMonthEntries
            }
        });
    }

    const chartOptions = {
        chart: {
            height: 420,
            type: 'area',
            width: 500,
            fontFamily: 'Inter, sans-serif',
            foreColor: '#6B7280',
            toolbar: {
                show: true,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                enabled: true,
                opacityFrom: 0.45,
                opacityTo: 0,
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Inter, sans-serif',
            },
        },
        grid: {
            show: true,
            borderColor: '#F3F4F6',
            strokeDashArray: 1,
            padding: {
                left: 35,
                bottom: 15,
            },
        },

        markers: {
            size: 5,
            strokeColors: '#ffffff',
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },

        xaxis: {
            categories: Object.keys(topEntries)?.length > 0 ? Object.keys(topEntries[0]?.entries) : [],
            labels: {
                style: {
                    colors: ['#6B7280'],
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
            axisBorder: {
                color: '#F3F4F6',
            },
            axisTicks: {
                color: '#F3F4F6',
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#F3F4F6',
                    width: 1,
                    dashArray: 10,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ['#6B7280'],
                    fontSize: '14px',
                    fontWeight: 500,
                }
            },
            title: {
                text: 'Entry Count',
                style: {
                    color: '#6B7280',
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
        },
        title: {
            text: 'Total Entries',
            align: 'left',
            style: {
                fontSize: '25px',
                color: 'rgb(30, 29, 29)',
                padding: '0px 0px 0px 40px'
            },
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    xaxis: {
                        labels: {
                            show: false,
                        },
                    },
                },
            },
        ],
        legend: {
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter, sans-serif',
            labels: {
                colors: ['#6B7280'],
            },
            itemMargin: {
                horizontal: 10,
            },
        },
        series: chartData,
    };



    return (
        <>
            <div className="sm:col-span-2 md:col-span-4 flex flex-col flex-auto overflow-hidden">

                <div className="grid grid-cols-1 grid-flow-row gap-4 w-full mt-8 sm:mt-4">

                    <div className="flex flex-col flex-auto">

                        <div
                            className="sm:col-span-2 lg:col-span-3 bg-white bg-opacity-30 border border-gray-200 shadow bg-gray-100 dark:bg-gray-700 dark:border-gray-700 shadow-sm flex flex-col flex-auto rounded-2xl overflow-hidden">

                            <div className="flex flex-col text-gray-700 dark:text-white dark:bg-gray-700 pl-8 pt-4">
                                <div
                                    className="mr-4 text-2xl md:text-3xl font-semibold tracking-tight leading-7">Engagement
                                    Overview
                                </div>
                                <div className="font-medium text-secondary mt-4">Number of unique sends</div>
                            </div>

                           
                            {topEntries?.length ?  ( <div className="p-4 2xl:col-span-2 fade-in"> <Chart options={chartOptions} series={chartOptions.series}
                               type="area" height={350}/></div>) : (<div className="h-[397px]"><Spinner /></div>)}
                   
                        </div>

                    </div>


                    {/* <div className="flex flex-col">
                        <div className="flex-auto grid grid-cols-4 gap-4">

                            <div className="col-span-2">
                                <PillChart/>
                            </div>

                            <div className="col-span-2">
                                <PillChartB/>
                            </div>

                            <div
                                className="col-span-2 sm:col-span-1 flex flex-col items-center justify-center text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-700 py-4 sm:py-8 px-1 rounded-2xl border border-gray-200 shadow dark:bg-gray-700 dark:border-gray-700 shadow-sm bg-opacity-30">
                                <div className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">3</div>
                                <div className="mt-1 text-xs sm:text-sm text-center">Fixed</div>
                            </div>
                            <div
                                className="col-span-2 sm:col-span-1 flex flex-col items-center justify-center text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-700 py-4 sm:py-8 px-1 rounded-2xl border border-gray-200 shadow dark:bg-gray-700 dark:border-gray-700 shadow-sm bg-opacity-30">
                                <div className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">4</div>
                                <div className="mt-1 text-xs sm:text-sm text-center">Won't Fix</div>
                            </div>
                            <div
                                className="col-span-2 sm:col-span-1 flex flex-col items-center justify-center text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-700 py-4 sm:py-8 px-1 rounded-2xl border border-gray-200 shadow dark:bg-gray-700 dark:border-gray-700 shadow-sm bg-opacity-30">
                                <div className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">8</div>
                                <div className="mt-1 text-xs sm:text-sm text-center">Re-opened</div>
                            </div>
                            <div
                                className="col-span-2 sm:col-span-1 flex flex-col items-center justify-center text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-700 py-4 sm:py-8 px-1 rounded-2xl border border-gray-200 shadow dark:bg-gray-700 dark:border-gray-700 shadow-sm bg-opacity-30">
                                <div className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">6</div>
                                <div className="mt-1 text-xs sm:text-sm text-center">Needs Triage</div>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
        </>
    )
}

export default DashboardHeader
