import {MenuAlt2Icon } from "@heroicons/react/outline";

export default function MobileMenuButton({setSidebarOpen}){
    return(
        <button
            type="button"
            className="px-4 text-gray-900 dark:text-gray-200 bg-white dark:bg-gray-900 md:hidden"
            onClick={() => setSidebarOpen(true)}
        >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-8 w-8" aria-hidden="true" />
        </button>
    )
}
