import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchAPI} from "../../../helpers/fetchAPI";

export const getPageAccessToken = createAsyncThunk(
  "setting/getPageAccessToken",
  async (payload) => {
    return await fetchAPI(`/api/settings/generate-pat`, payload);
  }
);

export const removePageAccessToken = createAsyncThunk(
  "setting/removePageAccessToken",
  async (payload) => {
    return await fetchAPI(`/api/settings/remove-pat`, payload);
  }
);
export const postToFBPage = createAsyncThunk(
  'entry/postToFacebook',
  async (payload) => {
      return await fetchAPI(`/api/entry/postToFacebook`, payload)
  }
);
