import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {setAlert, setLoading} from "../../store/features/app";
import {useAuth} from "../../hooks/useAuth";
import {PersonalDetailsForm, AddressForm} from "../../components/Forms";
import {InputField, FileInputField} from "../../components/UI/FormControls";
import {addCompany, getCompanies} from "../../store/features/company/thunks";
import {uploadToSB, createSBFolder} from "../../helpers/sbStorage";
import companyLogo from "../../assets/images/default-logo.jpeg";

const AddCompany = () => {
  const formInitialValues = {
    logo: "",
    companyname: "",
    websiteurl: "",
    addresslineone: "",
    addresslinetwo: "",
    city: "",
    state: "",
    postcode: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    title: "",
  };

  const validationSchema = Yup.object().shape({
    companyname: Yup.string().required(`Company name is required`),
    firstname: Yup.string().required(`First name is required`),
    lastname: Yup.string().required(`Last name is required`),
    email: Yup.string()
      .email(`Enter valid Email Address`)
      .required(`Email is required`),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$*.\[\]{}()?"!@#%\^&\/\\,><':;|_~`=+-])[A-Za-z\d$*.\[\]{}()?"!@#%\^&\/\\,><':;|_~`=+-]{8,}$/,
        "Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
      ),
    addresslineone: Yup.string().required(`Address Line 1 is required`),
    city: Yup.string().nullable().required(`City is required`),
    state: Yup.string().nullable().required(`State is required`),
    postcode: Yup.string()
      .required(`Postcode is required`)
      .test(
        "len",
        `Postcode is not valid ( e.g. 3000)`,
        (val) => val && val.length === 4
      ),
  });

  const auth = useAuth();
  const dispatch = useDispatch();
  const [hasUploadError, setHasUploadError] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [resetFile, setResetFile] = useState(null);
  const fileUploadCB = (file) => {
    setFileToUpload(file);
  };

  const handleSubmit = async (values, actions) => {
    dispatch(setLoading(true));
    actions.setTouched({});
    actions.setSubmitting(true);
    let logoFile = "";
    if (!fileToUpload) {
      const blob = await (await fetch(companyLogo)).blob();
      logoFile = new File([blob], "default-logo.png", {
        type: "image/jpeg",
        lastModified: new Date(),
      });
    } else {
      logoFile = fileToUpload;
    }

    const uploadedFile = await uploadToSB({
      companyName: values.companyname,
      fileToUpload: logoFile,
      dispatch,
    });

    if (uploadedFile.data) {
      values.logo = uploadedFile.data;
    }

    values.userId = auth.user?.id;

    try {
      dispatch(addCompany({data: values})).then((res) => {
        if (res.payload.error) {
          dispatch(
            setAlert({
              show: true,
              type: "error",
              content: res.payload.error,
            })
          );
          dispatch(setLoading(false));
        } else {
          dispatch(
            setAlert({
              show: true,
              type: "success",
              content: "Successfully created company",
            })
          );
          setResetFile(true);
          actions.resetForm();
          (async () => {
            await dispatch(getCompanies());
            dispatch(setLoading(false));
          })();
        }
      });
    } catch (error) {}

    actions.setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form id="add-company" className="max-w-4xl">
          <div>
            <div className="space-y-6 py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-4xl sm:text-4xl text-slate-800 font-extrabold leading-tight tracking-tight">
                  Add Company
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please provide the following information about your company.
                </p>
              </div>

              <FileInputField fileUploadCB={fileUploadCB} reset={resetFile} />

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <InputField
                    name="companyname"
                    label="Name of the company"
                    type="text"
                    id="companyname"
                    autoComplete="given-name"
                    className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <InputField
                    name="websiteurl"
                    label="Website Url"
                    id="websiteurl"
                    className="block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <AddressForm />
              </div>
            </div>
          </div>

          <div className="p-5">
            <hr className="pt-7" />
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Administrator Details
              </h3>
              <p className="mb-5 text-sm text-gray-500">
                Communication details in case we want to connect with you. These
                will be kept private.
              </p>
            </div>

            <div>
              <PersonalDetailsForm
                showPasswordField
                showTitleField
                showPhoneField
              />
            </div>

            <hr className="mt-7" />

            <div className="mx-auto max-w-7xl mt-10 mb-10 text-right">
              <div className="col-start-1 col-end-3">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default AddCompany;
