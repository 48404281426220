import {configureStore} from '@reduxjs/toolkit';

import appReducer from './features/app'
import roleReducer from './features/role'
import userReducer from './features/user/slice'
import dashboardReducer from './features/dashboard/slice'
// import companyReducer from './features/company/slice'
import templateReducer from './features/template/slice'
// import settingsReducer from './features/settings'
import facebookReducer from './features/facebook/slice'
import notificationsReducer from './features/notifications/slice'
import entryReducer from './features/entry/slice'
export const store = configureStore({
    reducer: {
        role: roleReducer,
        app: appReducer,
        user: userReducer,
        // company: companyReducer,
        template: templateReducer,
        // settings: settingsReducer,
        notifications: notificationsReducer,
        entry: entryReducer,
        dashboard: dashboardReducer,
        facebook: facebookReducer, 
    },
});
