export default {
    company:{
        name:"",
        logo:"",
        website:"",
        addresslineone:"",
        addresslinetwo:"",
        city:"",
        state:"",
        postcode:"",
        contactnumber:"",
    },
    customer:{
        firstname:"",
        lastname:"",
        email:"",
        contactnumber:"",
        photo:"https://e-builder.s3.ap-southeast-2.amazonaws.com/sample-welcome-image.jpg",
        addresslineone:"",
        addresslinetwo:"",
        city:"",
        state:"",
        postCode:"",
        product:"",
    }
}