import {createSlice} from '@reduxjs/toolkit'
import facebookInitialState from "./initialState";
import { getPageAccessToken, removePageAccessToken, postToFBPage } from './thunks'

const facebookSlice = createSlice({
    name: 'facebook',
    initialState: facebookInitialState,
    reducers: {
        setUserAccessToken(state, {payload}){
            state.longLivedUserToken = payload;
        }
    },
    extraReducers: {

        [getPageAccessToken.fulfilled]: (state, {payload}) => {
            state.pageAccessToken = payload?.data?.access_token ? payload.data.access_token  : null; 
        },

        [removePageAccessToken.fulfilled]: (state, {payload}) => {
            state.pageAccessToken = ''
        },
        [postToFBPage.fulfilled]: (state,{payload}) => {
            return {...state, ...payload}
        },

    },
})

export default facebookSlice.reducer
