import {createSlice} from '@reduxjs/toolkit'
import userInitialState from "./initialState";
import {addUser, getUserById, getUsers, updateUser, updateCompany, updateSettings, getPageAccessToken,removePageAccessToken} from './thunks'

const userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {},
    extraReducers: {
        [getUsers.fulfilled]: (state, {payload}) => {

            state.list = payload.data
        },
        [getUserById.fulfilled]: (state, {payload}) => {
            state.edited = payload
        },
        [updateUser.fulfilled]: (state, {payload}) => {
            state.edited = payload
        },
        [addUser.fulfilled]: (state, {payload}) => {
            state.isLoading = false
        },
        [getUserById.fulfilled]: (state, {payload}) => {
            const {data} = payload;
            return {...state, ...data}
        },
        [updateCompany.fulfilled]: (state, {payload}) => {
            state.company = payload.data[0]
        },
        [updateSettings.fulfilled]: (state, {payload}) => {

            state.company.settings = payload.data[0]
        },
        // [getPageAccessToken.fulfilled]: (state, {payload}) => {

        //     state.company.settings.pageaccesstoken = payload.data.access_token

        // },
        // [removePageAccessToken.fulfilled]: (state, {payload}) => {

        //     state.company.settings.pageaccesstoken = ''

        // },
      



    },
})

export default userSlice.reducer
