import {useParams} from "react-router-dom";
import DOMPurify from 'dompurify';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getNotificationById} from "../../store/features/notifications/thunks";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import moment from "moment"
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const EntryView = () => {
    let params = useParams();
    const dispatch = useDispatch()
    const notification = useSelector(state => state.notifications)

    useEffect(() => {
        if (params.id) {

            dispatch(getNotificationById({id:params.id})).then(res=>{

            })
        }
    }, [dispatch, params])
    return (
        < >

            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Product Information</h3>
                </div>
                <div>
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 dark:bg-gray-900 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium dark:text-gray-200 text-gray-500">Product</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {notification.entry.product}
                            </dd>
                        </div>
                        <div className="bg-white dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium dark:text-gray-200 text-gray-500">Photo</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {notification.entry.photo ? <img src={notification.entry.photo} alt={notification.entry.product} />: 'Photo not uploaded'}
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Customer Information</h3>
                </div>
                <div>
                    <dl>
                        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Full Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                {notification.entry.firstname} {notification.entry.lastname}
                            </dd>
                        </div>
                        <div className="bg-white dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Email</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                {notification.entry.email}
                            </dd>
                        </div>
                        <div className="bg-white dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Contact Number</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                {notification.entry.contactnumber}
                            </dd>
                        </div>
                        <div className="bg-white dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Address</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                {notification.entry.addresslineone}<br/>
                                {notification.entry.addresslinetwo ? `${notification.entry.addresslineone} <br/>` : ''}
                                {notification.entry.city}<br/>
                                {notification.entry.state} - {notification.entry.postcode}
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">SMS</h3>
                </div>
                <div>
                    <dl>
                        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <button
                                    type="button"
                                    className={ classNames(
                                            notification.smssent ? "bg-green-700 hover:bg-green-700 focus:ring-green-700" : "bg-red-600 hover:bg-red-700 focus:ring-red-500",
                                            "inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none"
                                            )}
                                      >
                                    {notification.smssent ? (
                                        <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />):(
                                        <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                    )}
                                    {notification.smssent ? "Sent" : "Not Sent"}
                                </button>
                            </dd>
                        </div>
                        <div className="bg-white dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Content</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">{notification.smscontent}</dd>
                        </div>

                    </dl>
                </div>
            </div>

            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Facebook</h3>
                </div>
                <div>
                    <dl>
                        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                <button
                                    type="button"
                                    className={ classNames(
                                        notification.fbposted ? "bg-green-700 hover:bg-green-700 focus:ring-green-700" : "bg-red-600 hover:bg-red-700 focus:ring-red-500",
                                        "inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                    )}
                                >
                                    {notification.fbposted ? (
                                        <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />):(
                                        <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                    )}
                                    {notification.fbposted ? "Posted" : "Not Posted"}
                                </button>
                                 {notification.fbscheduled ?
                                     <p className="mt-3">
                                         Scheduled to post on {' '}<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                                            {moment(notification.fbscheduledon).format('DD-MM-YYYY HH:mm')}
                                         </span>
                                     </p>
                                     : '' }
                            </dd>
                        </div>
                        <div className="bg-white dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Content</dt>
                            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                                {
                                    notification.fbpostcontent ? notification.fbpostcontent : "Content not available"
                                }

                                {
                                    notification.entry.photo && <img src={notification.entry.photo} />
                                }
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>

            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Email Template</h3>
                </div>
                <div>
                    <dl>
                        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <button
                                    type="button"
                                    className={ classNames(
                                        notification.emailsent ? "bg-green-700 hover:bg-green-700 focus:ring-green-700" : "bg-red-600 hover:bg-red-700 focus:ring-red-500",
                                        "inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                    )}
                                >
                                    {notification.emailsent ? (
                                        <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />):(
                                        <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                    )}
                                    {notification.emailsent ? "Sent" : "Not sent"}
                                </button>
                            </dd>
                        </div>
                        <div className="mt-10 flex justify-center">

                            <dd>
                                {
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.emailcontent ? notification.emailcontent : "Content not available") }} />

                                }
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>
        </>

    )
}

export default EntryView
