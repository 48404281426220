import {reverse, sortBy} from "lodash";
import {Liquid} from 'liquidjs'

export const sortData =  (items, sortByProp, propType="number") =>{
    switch(propType){
        case "number" :
            return reverse(sortBy(items, [(item) => parseInt(item[sortByProp])]))
        case "dateTime" :
            return reverse(sortBy(items, [(item) => item[sortByProp]]))
    }


}
export const getFormattedRoles =  (userRole, roles) => {
    const sortedRoles =  sortData(roles, "accesslevel")

    let formattedRoles = []
    sortedRoles.forEach(role => {
        if (parseInt(role.accesslevel) <= userRole) {
            formattedRoles.push({
                value: role.id,
                label: role.name
            })
        }

    });

    return [{value: "", label: "Select role"}, ...formattedRoles];
}


export const slugify = (text) => {
    return text
        .normalize('NFKD')
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .trim()
        .replace(/[-\s]+/g, '-');

}


export const mergeTagsToHtml = (html, mergeTags) =>{
    const engine = new Liquid();
    const tpl = engine.parse(html);
    return engine.renderSync(tpl, mergeTags);
}
