import React from 'react'
import { ThemeContext } from './themeContext'
import lightMode from '../../assets/images/sun.svg';
import darkMode from '../../assets/images/moon.svg';

export const Toggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext)

  function isDark() {
    return theme === 'dark'
  }


  return (
    <>
      <label>
            <img
                 className="cursor-pointer self-left justify-self-end"
                 src="/assets/moon.svg"
                 src={isDark() ? lightMode : darkMode}
                 width="25"
                 height="25"/>
        <input
          className="hidden"
          type="checkbox"
          checked={isDark()}
          onChange={e => setTheme(e.target.checked ? 'dark' : 'light')}
        ></input>
      </label>
    </>
  )
}
