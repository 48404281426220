import {Navigate, useLocation, Outlet} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import AppLayout from "./layout";

const PrivateOutlet = () => {
    const {user} = useAuth();

    const {pathname} = useLocation();

    return user ? (
        <AppLayout>
            <Outlet/>
        </AppLayout>
    ) : (
        <Navigate to="/login" state={{from: pathname}} replace/>
    );
};

export default PrivateOutlet;
