const leads = [
    {value: "", label: "Select Lead Type"},
    {value: "Autotrader.com.au", label: "Autotrader.com.au"},
    {value: "Carsales.come.au", label: "Carsales.come.au"},
    {value: "carsGuide.com.au", label: "carsGuide.com.au"},
    {value: "Cinema", label: "Cinema"},
    {value: "Dealer Website", label: "Dealer Website"},
    {value: "Direct Mail", label: "Direct Mail"},
    {value: "Drive By", label: "Drive By"},
    {value: "Email", label: "Email"},
    {value: "Facebook", label: "Facebook"},
    {value: "Google", label: "Google"},
    {value: "Instagram", label: "Instagram"},
    {value: "Local Paper", label: "Local Paper"},
    {value: "Manufacture Website", label: "Manufacture Website"},
    {value: "Phone Call", label: "Phone Call"},
    {value: "Radio", label: "Radio"},
    {value: "Referral", label: "Referral"},
    {value: "Repeat Customer", label: "Repeat Customer"},
    {value: "Shopping Centre", label: "Shopping Centre"},
    {value: "SMS", label: "SMS"},
    {value: "Sponsorship", label: "Sponsorship"},
    {value: "TV", label: "TV"},
    {value: "Vehicle Signage", label: "Vehicle Signage"},
]

export default leads;