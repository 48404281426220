import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import  Dashboard  from './views/Dashboard'
import { AuthProvider } from './hooks/useAuth'
import  Login  from './views/Login'
import PrivateOutlet from './components/PrivateOutlet'
import CompanyProfile from "./views/Company/CompanyProfile";
import EditCompany from "./views/Company/EditCompany";
import Users from "./views/User/Users";
import UserProfile from "./views/User/UserProfile";
import EditUser from "./views/User/EditUser";
import AddUser from "./views/User/AddUser";
import Templates from "./views/Template/Templates";
import Social from "./views/Settings/Social";
import Presets from "./views/Settings/Presets";
import AccessTokens from "./views/Settings/AccessTokens";
import AddEntry from "./views/Entry/AddEntry";
import Entries from "./views/Entry/Entries";
import EntryView from "./views/Entry/EntryView";
import AddCompany from "./views/Company/AddCompany";
import FacebookSettings from "./views/Settings/FacebookSettings";
// import InstagramSettings from "./views/Settings/InstagramSettings"; INSTAGRAM SETTINGS

import HelpCenter from "./views/HelpCenter/HelpCenter";
import FAQS from "./views/HelpCenter/FAQS";
import GUIDES from "./views/HelpCenter/GUIDES";

import SETTING from "./views/Account/SETTING";

import FACEBOOK from "./views/Facebook/FACEBOOK";

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/*" element={<PrivateOutlet />}>
                            <Route path="entries" element={<Entries />} />
                            <Route path="" element={<Dashboard />} />

                            <Route path="facebook" element={<FACEBOOK />} />

                            <Route path="users" element={<Users />} />
                            <Route path="user/:id" element={<UserProfile />} />
                            <Route path="user/edit/:id" element={<EditUser />} />
                            <Route path="user/add" element={<AddUser />} />

                            <Route path="company" element={<CompanyProfile />} />
                            <Route path="company/edit" element={<EditCompany />} />
                            <Route path="company/add" element={<AddCompany />} />

                            <Route path="help-center" element={<HelpCenter />} />
                            <Route path="faqs" element={<FAQS />} />
                            <Route path="guides" element={<GUIDES />} />

                            <Route path="account" element={<SETTING />} />

                            <Route path="settings/presets" element={<Presets />} />
                            <Route path="settings/facebook" element={<FacebookSettings />} />
                            {/* <Route path="settings/instagram" element={<InstagramSettings />} /> */}

                            <Route path="templates" element={<Templates />} />
                            <Route path="entry/add" element={<AddEntry />} />
                            <Route path="entry/:id" element={<EntryView />} />
                            <Route path="entries" element={<Entries />} />
                        </Route>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
