import { useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";


const CompanyProfile = () => {

    const navigate = useNavigate();
    const company = useSelector(state => state.user?.company)
    const auth = useAuth()

    const fullAddressLine = () => {
        return company.addresslinetwo ? `${company.addresslineone} <br/> ${company.addresslinetwo}, ` : `${company.addresslineone}, `
    }

    return (
        <>
            {company && <div className="bg-white mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-20 overflow-hidden sm:rounded-lg">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Logo</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-16">
                             <img alt='' src={company.logo}/>
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{company.name}</dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Website</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{company.website}</dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Address</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {fullAddressLine()} <br/>
                                {company.city} <br/>{company.state} - {company.postcode}
                            </dd>
                        </div>
                        {auth.userRole >= 5 && <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">

                                <button
                                    type="button"
                                    onClick={() => navigate(`/company/edit/`)}
                                    className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none"
                                >
                                    Edit Profile
                                </button>
                            </dt>
                        </div>}
                    </dl>
                </div>
            </div>}
        </>
    )
}

export default CompanyProfile;
