import React, { useEffect } from 'react';
import {getTopUsersWithDailyCount} from "../../store/features/dashboard/thunks";
import { useSelector, useDispatch } from 'react-redux';
import Chart from 'react-apexcharts';
import Spinner from '../../components/UI/Spinner.js'

const LeaderBoardChart = () => {
const companyId = "28d5a1f1-39ff-4fa7-b33a-14e4e440143d";
// const companyId = useSelector((state) => state.user?.company?.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
        console.log('are we dispatching')
        dispatch(getTopUsersWithDailyCount({companyId}))
    }
}, [companyId])
  
  const topUsersWithDailyCount = useSelector(state => state.dashboard?.topUsersWithDailyCount)
  console.log(topUsersWithDailyCount, 'leaderboard chart data')

const chartOptions = {
  chart: {
      height: 420,
      type: 'area',
      fontFamily: 'Inter, sans-serif',
      foreColor: '#6B7280',
      toolbar: {
          show: true,
      },
  },
  fill: {
      type: 'gradient',
      gradient: {
          enabled: true,
          opacityFrom: 0.45,
          opacityTo: 0,
      },
  },
  dataLabels: {
      enabled: false,
  },
  tooltip: {
      style: {
          fontSize: '14px',
          fontFamily: 'Inter, sans-serif',
      },
  },
  grid: {
      show: true,
      borderColor: '#F3F4F6',
      strokeDashArray: 1,
      padding: {
          left: 35,
          bottom: 15,
      },
  },

  markers: {
      size: 5,
      strokeColors: '#ffffff',
      hover: {
          size: undefined,
          sizeOffset: 3,
      },
  },

  xaxis: {
      categories: topUsersWithDailyCount.length > 0 ? Object.keys(topUsersWithDailyCount[0]?.entry_counts_by_date) : [],
      labels: {
          style: {
              colors: ['#6B7280'],
              fontSize: '14px',
              fontWeight: 500,
          },
      },
      axisBorder: {
          color: '#F3F4F6',
      },
      axisTicks: {
          color: '#F3F4F6',
      },
      crosshairs: {
          show: true,
          position: 'back',
          stroke: {
              color: '#F3F4F6',
              width: 1,
              dashArray: 10,
          },
      },
  },
  yaxis: {
      labels: {
          style: {
              colors: ['#6B7280'],
              fontSize: '14px',
              fontWeight: 500,
          }
      },
      title: {
          text: 'Entry Count',
          style: {
              color: '#6B7280',
              fontSize: '14px',
              fontWeight: 500,
          },
      },
  },
  title: {
      text: '',
      align: 'left',
      style: {
          fontSize: '16px',
      },
  },
  responsive: [
      {
          breakpoint: 1024,
          options: {
              xaxis: {
                  labels: {
                      show: false,
                  },
              },
          },
      },
  ],
  legend: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      labels: {
          colors: ['#6B7280'],
      },
      itemMargin: {
          horizontal: 10,
      },
  },
  noData: {
      text: "No Users Yet",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
          fontWeight: 'bold',
          fontSize: '14px',
      }
  },
  series: topUsersWithDailyCount?.map((item) => ({
      name: item.name,
      data: Object.values(item.entry_counts_by_date || []),
  })),

};



return (
  <div className="sm:col-span-2 lg:col-span-3 bg-white bg-opacity-30 border border-gray-200 shadow bg-gray-100 dark:bg-gray-700 dark:border-gray-700 shadow-sm flex flex-col flex-auto bg-card rounded-2xl overflow-hidden">
    <div className="flex flex-col text-gray-800 p-5 sm:pl-8 dark:text-white relative">
      {/* <div className="mr-4 mb-4 text-1xl lg:text-2xl font-semibold tracking-tight leading-7">Previous month ({previousMonthTotal} entries) to current ({currentMonthTotal} entries)</div> */}
      <div className="mr-4 mb-4 text-1xl lg:text-2xl font-semibold tracking-tight leading-7">Top Users By Entry</div>

      {/* <div className="flex items-center justify-center w-10 h-10 m-4 rounded-full border-2 border-gray-400 absolute top-0 right-0">
        <span className="block text-gray-800 dark:text-gray-200 font-bold"> {totalEntries}</span>
      </div> */}



    </div>
    <div  className="p-4">
     {topUsersWithDailyCount.length ? <Chart class="fade-in" options={chartOptions} series={chartOptions.series} type="area" height={350}/> : <div className="h-[365px]"><Spinner /></div>}
    </div>

   {/* <div className="bg-gray-100 dark:bg-gray-500">
    <ul  className="m-5 mt-7 mb-6 grid gap-5 grid-cols-2 sm:gap-6 lg:grid-cols-4 flex flex-col-reverse md:flex-row">
      {currentMonthData.map((user, index) => (
        <li key={`current_${user.name}_${user.entryCount}`} className="col-span-1 flex rounded-md">
        <div
        className='flex w-16 flex-shrink-0 items-center justify-center shadow shadow-sm rounded-md text-3xl font-semibold text-white bg-green-600'
        >
          {index + 1}
        </div>
        <div className="flex flex-1 items-center justify-between truncate">
              <div className="flex-1 truncate px-4 py-2 text-sm">
              <a href={user.name} className="font-medium text-gray-700 dark:text-gray-100 hover:text-gray-600">
                  {user.entryCount}
                </a>
                <p className="text-gray-500 dark:text-gray-400">({user.entryCount} entries)</p>
              </div>
          </div>
        </li>
      ))}{}
      {previousMonthData.map((user, index) => (
        <li key={`previous_${user.name}_${user.entryCount}`} className="col-span-1 flex rounded-md">
        <div
          className='flex w-16 flex-shrink-0 items-center justify-center shadow shadow-sm rounded-md text-3xl font-semibold text-white bg-green-600'
        >
          {user.entryCount}
        </div>
        <div className="flex flex-1 items-center justify-between truncate">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a href={user.name} className="font-medium text-gray-700 dark:text-gray-100 hover:text-gray-600">
                  {user.name}
                </a>
                <p className="text-gray-400 dark:text-gray-400">({user.entryCount} entries)</p>
              </div>
          </div>
        </li>
      ))}
    </ul>
  </div> */}

  </div>

);
};

export default LeaderBoardChart;
