import {Menu, Transition} from "@headlessui/react";
import {Fragment} from "react";
import {useAuth} from "../../hooks/useAuth";
import { NavLink} from "react-router-dom";
import {ChevronDownIcon, UserIcon} from "@heroicons/react/solid";
import {startCase, camelCase} from "lodash";
import {useSelector} from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProfileDropdown({ userNavigation}){
    const auth = useAuth()
    const user = useSelector(state=>state.user)
    return (
        <Menu as="div" className="relative">
            <div className="rounded-md">
                <Menu.Button className="w-full flex items-center text-sm p-2 rounded rounded-md">
                    <span className="flex-shrink-0 flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-600">
                    <UserIcon
                        className="h-6 w-6 text-slate-700 dark:text-gray-100"
                        aria-hidden="true"
                    />
                      </span>

                    <span className="ml-3 mr-3 text-sm font-medium text-left hidden md:block text-slate-700 dark:text-gray-300">
                        <span className="sr-only">Open user menu for </span>
                        <span className="block leading-[10px]">{startCase(camelCase(user?.firstname))} {startCase(camelCase(user?.lastname))}</span>
                        <span className="text-[11px] leading-[11px]">{startCase(camelCase(user?.role?.name))}</span>
                      </span>
                    <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-auto h-5 w-5 text-slate-700 dark:text-gray-100 lg:block"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                        auth.userRole >= item.roleLevel &&
                        <Menu.Item key={item.name}>
                            {({ active }) => (
                                <NavLink
                                    to={item.href}
                                    className={classNames(
                                        active ? 'bg-gray-100 dark:bg-gray-600 ' : '',
                                        'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white'
                                    )}
                                >
                                    {item.name}
                                </NavLink>


                            )}
                        </Menu.Item>

                    ))}
                    <Menu.Item key="10000">

                            <button type="button"
                                className= 'block px-4 py-2 text-sm text-slate-700 dark:text-gray-100 dark:hover:bg-gray-800'
                                onClick = {() => auth.signOut()}
                            >
                                Sign Out
                            </button>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>


    )
}
