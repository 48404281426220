import ComboBox from "../../components/UI/FormControls/ComboBox";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {FileInputField, SelectField} from "../../components/UI/FormControls";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";

import {getTemplates} from "../../store/features/template/thunks";
import TextAreaFieldWithTags from "../../components/UI/FormControls/TextAreaFieldWithTags";
import {updateSettings} from "../../store/features/user/thunks";

import RadioGroupField from "../../components/UI/FormControls/RadioGroupField";
import {setAlert, setLoading} from "../../store/features/app";
import {uploadToSB} from "../../helpers/sbStorage";
import Loader from "../../components/UI/Loader";
import {findIndex} from "lodash";

const formInitialValues = {
    postimagebanner:"",
    defaulttemplateid: '',
    smstemplate: '',
    facebooktemplate: '',
    postoptions: '',
    hourstodelay: ''
}

const fbPostOptions = [
    {id: "instant-post", label: "Instant post"},
    {id: "schedule-post", label: "Schedule post"}
]

const validationSchema = Yup.object().shape({
    defaulttemplateid: Yup.string()
        .required(`Default Template is required`),
    smstemplate: Yup.string()
        .required(`Default Template is required`),
    facebooktemplate: Yup.string()
        .required(`Default Template is required`),
    // postoptions: Yup.string()
    //     .required(`Facebook post options is required`),
    // hourstodelay: Yup.string()
    //     .when("postOptions",{
    //         is: "schedule-post",
    //         then: Yup.string().required(`Schedule information is required`),
    //     })
})


const Presets = () =>{

    const dispatch = useDispatch()
    const [templateOptions, setTemplateOptions] = useState([{value: "", label: "Select Template"}])
    const [showScheduler, setShowScheduler] = useState(false)

    const [companyId, setCompanyId] = useState(null)
    const [companyName, setCompanyName] = useState(null)
    const [setting, setSetting] = useState(null)

    const selectedCompany = useSelector(state => state.user?.company)

    useEffect(()=>{
        if(selectedCompany){
            setCompanyId(selectedCompany.id)
            setCompanyName(selectedCompany.name)
            setSetting(selectedCompany.settings)
        }
    },[selectedCompany])
    useEffect(() => {

        if (companyId) {
            dispatch(setLoading(true));
            dispatch(getTemplates({companyId})).then(res => {
                if (res.payload.error) {
                    dispatch(setAlert({
                        show: true,
                        type: "error",
                        content: "Encountered issue while retrieving templates"
                    }));
                    dispatch(setLoading(false));
                } else {
                    const templatesData = res.payload.data
                    setTemplateOptions([{value: "", label: "Select Template"}])
                    templatesData.map((template) => {
                        setTemplateOptions(state => [...state, {
                            value: template.id,
                            label: template.name
                        }])
                    })
                    dispatch(setLoading(false));
                }

            })
        }
    }, [companyId])

    const [fileToUpload, setFileToUpload] = useState(null)
    const fileUploadCB = (file) => {
        setFileToUpload(file)
    }

    const handleSubmit = async (values, actions) => {
        actions.setSubmitting(true);
        dispatch(setLoading(true))

        if (fileToUpload) {

            const uploadedFile = await uploadToSB({companyName, fileToUpload, dispatch})
            if (uploadedFile.data) {
                values.postimagebanner = uploadedFile.data
            }
        }

        dispatch(updateSettings(values)).then(async (res) => {
            if (res.payload.error) {
                dispatch(setAlert({ show: true, type: "error", content: res.payload.error }));
                dispatch(setLoading(false));
            } else {
                dispatch(setAlert({show: true, type: "success", content: "Successfully updated presets"}));
                dispatch(setLoading(false));
            }

        })

        actions.setSubmitting(false);
    }
    return (
        <>

                <Formik
                    initialValues={formInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, touched, isSubmitting, setFieldValue}) => {

                        useEffect(() => {

                            if (setting) {
                                if (setting.postoptions) {
                                    const postOptionObject = JSON.parse(setting.postoptions)
                                    setFieldValue('postoptions', fbPostOptions[findIndex(fbPostOptions, postOptionObject)])
                                    setShowScheduler(postOptionObject.id === 'schedule-post')

                                }else{
                                    setFieldValue(fbPostOptions[0])
                                }
                                setFieldValue('defaulttemplateid', setting.defaulttemplateid ?? '')
                                setFieldValue('smstemplate', setting.smstemplate ?? '')
                                setFieldValue('facebooktemplate', setting.facebooktemplate ?? '')
                                // setFieldValue('postoptions', setting.postoptions ?? '')
                                setFieldValue('hourstodelay', setting.hourstodelay ?? '')

                            }
                        }, [setting])

                        useEffect(()=>{

                            if(values.postoptions?.id === 'schedule-post'){

                                setShowScheduler(true)
                            }else{
                                setShowScheduler(false)
                            }
                        },[values.postoptions])
                        return (
                            <Form id="add-facebook-settings" className="mx-auto max-w-7xl sm:px-6 lg:px-8 mb-20">
                                <div className="bg-white dark:bg-gray-700 dark:bg-gray-800 rounded-lg divide-y divide-gray-200 mt-5">
                                    <div className="px-4 pb-5">
                                        <div className=" grid grid-cols-1 gap-2">
                                            <div className="mt-3 col-span-1 sm:col-span-1">
                                                <FileInputField label={"Upload Post Image Banner"} image={setting?.postimagebanner} type="postImageBanner" fileUploadCB={fileUploadCB}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white dark:bg-gray-700 rounded-lg divide-y divide-gray-200 mt-5">
                                    <div className="px-4 pb-5">
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className="col-span-2 sm:col-span-1">
                                                <ComboBox name="defaulttemplateid" label="Select default template"
                                                             items={templateOptions}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white dark:bg-gray-700 overflow-hidden rounded-lg divide-y divide-gray-200 mt-5">
                                    <div className="px-4 pb-5">
                                        <div className="grid grid-cols-1 gap-2">
                                            <div className="col-span-2 sm:col-span-1">
                                                <TextAreaFieldWithTags name="smstemplate" label="Default SMS Template"
                                                                       rows={8}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white dark:bg-gray-700 overflow-hidden rounded-lg divide-y divide-gray-200 mt-5">
                                    <div className="px-4 pb-5">
                                        <div className="grid grid-cols-1 gap-2">
                                            <div className="col-span-2 sm:col-span-1">
                                                <TextAreaFieldWithTags name="facebooktemplate"
                                                                       label="Default Facebook Template" rows={8}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white dark:bg-gray-700 overflow-hidden rounded-lg divide-y divide-gray-200 mt-5">
                                    <div className="px-4 pb-5">
                                        <div className="grid grid-cols-1 gap-2">
                                            <div className="col-span-2 sm:col-span-1 mt-4">
                                                <RadioGroupField name="postoptions" label="Facebook Post Options"
                                                                 options={fbPostOptions}/>
                                            </div>
                                            {showScheduler && <div className="col-span-2 sm:col-span-1">
                                                <SelectField name="hourstodelay"
                                                             className="w-48 bg-white dark:bg-gray-700"
                                                             label="Hours to delay posting"
                                                             data={[
                                                                 {value:"", label:"Select Hours"},
                                                                 {value: "1", label: "1"},
                                                                 {value: "2", label: "2"},
                                                                 {value: "3", label: "3"},
                                                                 {value: "4", label: "4"},
                                                                 {value: "5", label: "5"}
                                                             ]}/>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2 sm:col-span-1 mt-10">
                                    <button type="submit"
                                            className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none">
                                        Save
                                    </button>
                                </div>
                            </Form>
                        )
                    }}

                </Formik>

        </>
        )

}

export default Presets
