import {PlusIcon} from "@heroicons/react/solid";

const CreateButton = ({text, ...props}) =>{
    return(
        <div className="flex justify-end">
            <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent gradient-bg px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none"
                {...props}
            >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
                {text}
            </button>
        </div>
    )
}

export default CreateButton
