/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationIcon} from '@heroicons/react/outline'
import {useDispatch, useSelector} from "react-redux";
import {getUsers, updateUser} from "../../../store/features/user/thunks";
import {setAlert, setDeactivate} from "../../../store/features/app";
import {getTemplates, updateTemplate} from "../../../store/features/template/thunks";

const DeactivateModal = () => {
    const deactivateState = useSelector(state => state.app?.deactivate)
    const userId= useSelector(state => state.user?.id)
    const companyId= useSelector(state => state.user?.company?.id)
    const {
        show,
        entity,
        action,
        id,
        content
    } = deactivateState
    const dispatch = useDispatch();
    const cancelButtonRef = useRef(null)

    const hideDeactivateModal = () => {
        dispatch(setDeactivate({show: false, entity: "", action: "", id: "", content: ""}))
    }

    const deactivateEntityRecord = () => {
        const active = action !== "Deactivate";

        switch (entity) {
            case "user":
                dispatch(updateUser({id, data: {userId, active}})).then(res => {
                    if (res.payload.error) {
                        dispatch(setAlert({
                            show: true,
                            type: "error",
                            content: res.payload.error
                        }));
                    }else{
                        dispatch(setAlert({show: true, type: "success", content: `Successfully ${action}ed user`}));
                        (async () => await dispatch(getUsers({companyId})))()
                    }
                });
                break;
            case "template":
                dispatch(updateTemplate({id, data: {active}})).then(res => {
                    if (res.meta.requestStatus === 'fulfilled') {
                        dispatch(setAlert({show: true, type: "success", content: `Successfully ${action}ed template`}));
                        (async () => await dispatch(getTemplates({companyId})))()
                    }
                });
                break;
        }
        hideDeactivateModal()
    }
    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef}
                    onClose={hideDeactivateModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div
                                        className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {action} {entity}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={deactivateEntityRecord}
                                    >
                                        {action}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={hideDeactivateModal}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default DeactivateModal;
