import React, {useEffect, useRef, useState} from 'react';
import {at} from 'lodash';
import {useField} from 'formik';
import mergeTags from "../../../data/mergeTags";
import {SelectField} from "./index";

const TextAreaFieldWithTags = (props) => {
    const textAreaRef = useRef("")
    const {name, label, ...rest} = props;
    const [field, meta, helpers] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const [companyMergeTags, setCompanyMergeTags] = useState([{value: "", label: "Company Tags"}])
    const [customerMergeTags, setCustomerMergeTags] = useState([{value: "", label: "Customer Tags"}])
    const isError = touched && error;

    function renderHelperText() {
        return <p className="text-rose-500">{error}</p>;
    }

    useEffect(() => {
        if (mergeTags) {
            setCompanyMergeTags([{value: "", label: "Company Tags"}])
            for (const key in mergeTags.company) {
                setCompanyMergeTags(state => [...state, {value: `{{company.${key}}}`, label: key}])
            }

            setCustomerMergeTags([{value: "", label: "Customer Tags"}])
            for (const key in mergeTags.customer) {
                setCustomerMergeTags(state => [...state, {value: `{{customer.${key}}}`, label: key}])
            }

        }
    }, [mergeTags])

    const handleSelectChange = (e) => {
        textAreaRef.current.focus()

        let cursorPosition = textAreaRef.current.selectionStart
        let textBeforeCursorPosition = textAreaRef.current.value.substring(0, cursorPosition)
        let textAfterCursorPosition = textAreaRef.current.value.substring(cursorPosition, textAreaRef.current.value.length)
        helpers.setValue(textBeforeCursorPosition + e.target.value + textAfterCursorPosition)
    }
    return (
        <div className="mt-5">
            <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                {label}
            </label>
            <div className="pb-4 pt-0 mt-4">
                <div>
                    <div className="relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3">
                        <div className="w-48">
                            <SelectField name="companyMergeTag"
                                         data={companyMergeTags}
                                         onChange={handleSelectChange}

                            />
                        </div>
                        <div className="w-48">
                            <SelectField name="customerMergeTag"
                                         data={customerMergeTags}
                                         onChange={handleSelectChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <textarea
                ref={textAreaRef}
                {...field} {...rest}
                className="shadow-sm block w-full bg-white dark:bg-gray-900 dark:text-gray-100 sm:text-sm border border-gray-300 dark:border-gray-600 rounded-md"
            />
            {isError ? renderHelperText() : ""}
        </div>
    );
}

export default TextAreaFieldWithTags;
