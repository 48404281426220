import Brand from "./Brand";
import MainNav from "./MainNav";
import {NavLink} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import ProfileDropdown from "./ProfileDropdown";

const Sidebar = ({brandContainerClass, navContainerClass, navClass, navigation, adminNav}) => {
    const auth = useAuth();
    const userNavigation = [
        {name: 'Your Profile', href: `/user/${auth.user ? auth.user.id : ''}`, roleLevel: 1,},
        {name: 'Company Profile', href: `/company`, roleLevel: 5},
        {name: 'Settings', href: '/settings/presets', roleLevel: 5},
    ]
    return (
      <>
        <div className="flex-1 flex flex-col overflow-y-auto min-h-0">
        <NavLink to="/" className="mt-2 sm:mt-8 ml-2 md:hidden"><Brand containerClass={brandContainerClass}/></NavLink>
            <div className="mt-4 space-y-1">
                <div className={navContainerClass}>
                    <MainNav navClass={navClass} navigation={navigation} adminNav={adminNav}/>
                </div>
            </div>


            {auth.userRole >= 80 &&

                    <div className="space-y-1 py-3">
                        <h3 className="px-3 ml-1 text-sm font-semibold dark:text-gray-400 uppercase tracking-wider"
                            id="projects-headline ">
                            Admin Section
                        </h3>
                        <div className="space-y-1" role="group"
                             aria-labelledby="projects-headline">
                            {adminNav?.map((item) => (
                                <div key={item.name}>
                                    {auth.userRole >= parseInt(item.roleLevel) && <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className="group flex items-center mt-2 px-3 py-2 text-1xl leading-tight tracking-tight dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >

                                        <div className="mt-1 flex items-center rounded shadow-sm block hover:shadow-xl p-2" style={{backgroundColor:item.iconColor}}>
                                            <item.icon
                                                className='flex-shrink-0 h-6 w-6 dark:text-gray-400'
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="mt-1 ml-3">
                                            <div className="text-1xl">{item.name}</div>
                                            <div className="text-xs dark:text-gray-400">{item.sub}</div>
                                        </div>

                                    </NavLink>}
                                </div>
                            ))}
                        </div>
                    </div>
                }
         </div>
         <div className="flex flex-shrink-0">
           <div className="text-gray-900 dark:text-gray-400 ml-2"><ProfileDropdown userNavigation={userNavigation}/></div>
         </div>
       </>
    )
}

export default Sidebar
