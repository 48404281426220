import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getNotifications} from "../../store/features/notifications/thunks";
import {getEntriesByMonthWithCount} from "../../store/features/entry/thunks";
import {setLoading} from "../../store/features/app";
import {DocumentDuplicateIcon} from "@heroicons/react/solid";
import templateImage from "../../assets/images/template.png";
import CreateButton from "../../components/UI/CreateButton";
import LeaderBoardChart from "../../views/Charts/leaderBoardChart";
import {EntriesByMonthWithCount} from "../../components/Entry/EntriesByMonthWithCount.js";

const Entries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addEntry, setAddEntry] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [entriesByMonthWithCount, setEntriesByMonthWithCount] = useState([]);
  const [displayEntries, setDisplayEntries] = useState([]);
  const notifications = useSelector((state) => state.notifications?.list);
  const companyId = useSelector((state) => state.user?.company?.id);

  useEffect(() => {
    if (addEntry) navigate("/entry/add", {replace: true});
  }, [addEntry]);

  useEffect(() => {
    if (dispatch) {
      dispatch(getEntriesByMonthWithCount({companyId})).then((res) => {
        console.log(res.payload.data, "entries by month with count");
        let orderedEntries = res?.payload?.data?.length
          ? res?.payload?.data
          : [];
        setEntriesByMonthWithCount(orderedEntries);
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (entriesByMonthWithCount.length) {
      setDisplayEntries(getPaginatedEntries());
    }
  }, [perPage, page, entriesByMonthWithCount]);


  const getPaginatedEntries = () => {
    const flattenedEntries = entriesByMonthWithCount.flatMap(
      ({month_name, entries}) =>
        entries.map((entry) => ({...entry, month_name}))
    );

    const entriesToDisplay = flattenedEntries.slice( (page - 1) * perPage, page * perPage);

    let entriesToDisplayByMonth = entriesByMonthWithCount.map((month) => {
      const entries = entriesToDisplay.filter((entry) => entry.month_name === month.month_name);
      return {...month, entries};
    });
   
    return entriesToDisplayByMonth.filter((month) => month.entries.length);
  };

  const startIdx = (page - 1) * perPage;
  const endIdx = startIdx + perPage;

  const totalEntries = entriesByMonthWithCount.reduce(
    (acc, curr) => acc + curr.entry_count,
    0
  );
  const totalPages = Math.ceil(totalEntries / perPage);

  console.log(totalPages, "total pages");

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  //console.log(notifications)
  return (
    <>
      <div className="m-5 sm:rounded-md min-h-screen  overflow-hidden">
        <div className="mb-10">
          <LeaderBoardChart />
        </div>

        <div>
          <div>
            <div className="sm:flex sm:items-center">
              <div className="w-full lg:flex-auto ml-5">
                <h1 className="text-base text-2xl md:text-3xl font-semibold tracking-tight leading-7 text-gray-900 dark:text-gray-200">
                  Submissions
                </h1>
                <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">
                  A list of all the submissions in your account including their
                  name, title, email and product.
                </p>
              </div>
              <div className="mt-4 mr-5 sm:mt-0 sm:ml-16 sm:flex-none">
                <CreateButton
                  text={"Submit an entry"}
                  onClick={() => setAddEntry(true)}
                />
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div>
                    <EntriesByMonthWithCount entriesByMonth={displayEntries} />
                    {/* <NotificationsTimeline notifications={displayNotifications} /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mt-3 pb-2">
              <p className="text-sm text-gray-700 dark:text-gray-400">
                Showing {startIdx + 1}-{Math.min(endIdx, totalEntries || 0)} of{" "}
                {totalEntries || 0} entries
              </p>
              <div className="flex items-center">
                <p className="text-sm text-gray-700 dark:text-gray-400 mr-2 hidden lg:block">
                  Items per page:
                </p>
                <select
                  value={perPage}
                  onChange={(e) => setPerPage(parseInt(e.target.value))}
                  className="rounded-md border border-gray-200 py-1.5 px-3 mr-2 w-24 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 hidden lg:block"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                </select>

                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={(e) => handlePageChange(Math.max(1, page - 1), e)}
                    disabled={page === 1}
                    style={page === 1 ? {opacity: "0.5"} : {}}
                    className="relative inline-flex items-center rounded-md border bg-white dark:border-gray-600 dark:bg-gray-700 px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={(e) =>
                      handlePageChange(Math.min(page + 1, totalPages), e)
                    }
                    disabled={page === totalPages}
                    style={page === totalPages ? {opacity: "0.5"} : {}}
                    className="relative ml-3 inline-flex items-center rounded-md border bg-white dark:border-gray-600 dark:bg-gray-700 px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Entries;
