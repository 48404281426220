import { useState, useEffect } from 'react';
import './assets/css/index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppLayout from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './components/dark/themeContext';

const root = createRoot(document.getElementById('root'));

function App() {

  return (
    <Provider store={store}>
      <ThemeProvider>
        <AppLayout />
      </ThemeProvider>
    </Provider>
  );
}

root.render(<App />);
reportWebVitals();
