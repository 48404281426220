import * as Yup from "yup";
const validationSchema = Yup.object().shape({
    product: Yup.string()
        .required(`Vehicle name is required`),
    // condition: Yup.string()
    //     .required(`Condition is required`),
    firstname: Yup.string()
        .required(`First name is required`),
    lastname: Yup.string()
        .required(`Last name is required`),
    email: Yup.string()
        .email(`Enter valid Email Address`)
        .required(`Email is required`),
    contactnumber: Yup.string()
        .required(`Contact number is required`),
    addresslineone: Yup.string()
        .required(`Address Line 1 is required`),
    city: Yup.string()
        .nullable()
        .required(`City is required`),
    state: Yup.string()
        .nullable()
        .required(`State is required`),
    postcode: Yup.string()
        .required(`Postcode is required`)
        .test(
            'len',
            `Postcode is not valid ( e.g. 3000)`,
            val => val && val.length === 4
        ),
    leadsource: Yup.string()
        .required(`Lead source is required`),
    // postOptions: Yup.string()
    //     .when("postOnFacebook",{
    //         is: true,
    //         then: Yup.string().required(`Facebook post options is required`),
    //     }),
    // hoursToDelay: Yup.string()
    //     .when("postOptions",{
    //         is: "schedule-post",
    //         then: Yup.string().required(`Schedule information is required`),
    //     })
})

export default validationSchema;