import Step from "./Step";
import StepSeparator from "./StepSeparator";
import {useDispatch, useSelector} from 'react-redux'
import {changeEntryStepStatus} from "../../../store/features/entry/slice";
import {useEffect, useState} from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Stepper(){
        const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const steps = useSelector(state => state.entry.steps)
    const dispatch = useDispatch()


    useEffect(() => {

        dispatch(changeEntryStepStatus(currentStepIndex))
    }, [currentStepIndex]);


    return(

        <nav aria-label="Progress" className="p-5 pb-0">
            <ol

                className="border-none rounded-md overflow-hidden lg:flex rounded-none"
            >
                {steps.map((step, stepIdx) => (
                    <li key={step.id} className="relative overflow-hidden lg:flex-1">
                        <div
                            className={classNames(
                                stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                                stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                                'overflow-hidden'
                            )}
                        >
                            <Step step={step} stepIdx={stepIdx} />

                            {stepIdx !== 0 ? (
                                <StepSeparator />
                            ) : null}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}
